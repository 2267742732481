<template>
	<div
		:class="{ show: showUpdatePassword }"
		class="update-password bg-white box-shadow border-radius"
	>
		<div class="d-flex flex-column">
			<div class="d-flex gap-3 p-3">
				<img src="@/assets/images/icons/user_icon_yellow.svg" />
				<div class="d-flex flex-column">
					<div class="d-flex align-items-center gap-3">
						<p class="mb-0">
							<b>{{ userLogged?.name }}</b>
						</p>
						<div
							v-if="userLogged?.role_id == 1"
							class="bg-yellow px-3 py-1 text-white br-6 text-center small"
						>
							{{ userLogged?.slug }}
						</div>
						<div
							v-else
							class="bg-yellow text-white px-3 py-1 br-6 text-center small"
						>
							{{ userLogged?.slug }}
						</div>
					</div>
					<p class="text-muted mb-0 f-14">{{ userLogged?.email }}</p>
				</div>
			</div>
			<hr class="m-0" />
			<div
				class="change-color p-3 cursor-pointer"
				@click="toggleUpdatePasswordDialog(true)"
			>
				<div>
					<b>Cambiar contraseña</b>
					<img
						class="float-right"
						src="@/assets/images/icons/arrow_blue.svg"
						width="24"
					/>
				</div>
			</div>
			<hr class="m-0" />
			<div class="p-3 cursor-pointer change-color" @click="logout()">
				<div>
					Cerrar sesión
					<img
						class="float-right"
						src="@/assets/images/icons/logout_red.svg"
						width="20"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState, mapMutations, mapActions } from "vuex";

	export default {
		components: {},
		computed: {
			firstTwoLetters() {
				return this.userLogged?.name.substring(0, 2);
			},
			...mapState(["userLogged", "showUpdatePassword"]),
		},
		methods: {
			...mapMutations([
				"toggleSidebar",
				"toggleUpdatePassword",
				"toggleUpdatePasswordDialog",
			]),
			...mapActions(["logout"]),
		},
	};
</script>

<style lang="scss" scoped>
	.update-password {
		position: fixed;
		top: 6.5rem;
		right: 1rem;
		width: 20rem;
		overflow: hidden;
		visibility: hidden;
		opacity: 0;
		transform: translateY(-1rem);
		transition: $transition;

		&.show {
			visibility: visible;
			opacity: 1;
			transform: translateY(0);
		}

		/*.change-color {
			transition: $transition;

			&:hover {
				background-color: $primary;
				color: white;
			}
		}*/
	}

	.f-14 {
		font-size: 14px;
	}

	.float-right {
		float: right;
	}
</style>
