<template>
	<Dialog
		v-model:visible="$store.state.songs.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<FormTitle :title="title" />
		</template>

		<form>
			<div class="row gy-3 pt-3">
				<div class="col-12 d-block">
					<template v-for="track in song.tracks" :key="track.id">
						<div :value="track.id"  class="col-12 mb-2">
							<div class="d-block">
								<span>{{ track.name }}</span>
								<img style="margin-left: 15px;" :id="'player_track'+track.id" src="@/assets/images/icons/play_icon.svg" @click="play(track.id)"/>
								<audio :id="'audio_track'+track.id" :src="baseURL + '/files/songs/'+ track.url"></audio>
							</div>
						</div>
					</template>
				</div>
			</div>
		</form>
	</Dialog>
</template>

<script>
	import Form from "vform";
	import { mapActions, mapState } from "vuex";

	export default {
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data: () => ({
			form: new Form(),
			title: `Pistas de la canción`,
		}),
		methods: {
			...mapActions([""]),
			play(id) {
                var audio = document.getElementById("audio_track" + id);
				audio.addEventListener('ended', function() {
					// Puedes realizar acciones adicionales aquí después de que el audio haya terminado de reproducirse.
					document.getElementById("player_track" + id).src = require('@/assets/images/icons/play_icon.svg');
				});

				if (!audio.paused) {
					document.getElementById("player_track" + id).src = require('@/assets/images/icons/play_icon.svg');
					this.song.tracks.map(function(track) {
						document.getElementById("player_track" + track.id).src = require('@/assets/images/icons/play_icon.svg');
					});
					audio.pause(); // Detener la reproducción si está en curso
					audio.currentTime = 0;

				} else {
					this.song.tracks.map(function(track) {
						document.getElementById("player_track" + track.id).src = require('@/assets/images/icons/play_icon.svg');
					});
					audio.pause();
					audio.currentTime = 0;
					document.getElementById("player_track" + id).src = require('@/assets/images/icons/stop_icon.svg');
					audio.play(); // Iniciar la reproducción si no se está reproduciendo
				}
            }
		},
		computed: {
			...mapState(["dialogDefaults", "baseURL", "songs", "song"]),
		},
		mounted() {
		},
	};
</script>
