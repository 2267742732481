<template>
	<DataTable
		v-model:selection="selectedIds"
		:value="list"
		:totalRecords="total"
		:rowHover="datatableDefaults.rowHover"
		:rows="datatableDefaults.rows"
		:rowsPerPageOptions="datatableDefaults.rowsPerPageOptions"
		:currentPageReportTemplate="datatableDefaults.currentPageReportTemplate"
		:paginator="datatableDefaults.paginator"
		:paginatorTemplate="datatableDefaults.paginatorTemplate"
		:loading="datatableDefaults.loading"
		:lazy="datatableDefaults.lazy"
		:responsiveLayout="datatableDefaults.responsiveLayout"
		:breakpoint="datatableDefaults.breakpoint"
		:scrollable="true"
		@page="changeCurrentPage($event)"
		@row-click="showRegister($event, 'show')"
		@row-reorder="onRowReorder($event)"
	>
		<template #header>
			<div class="row gy-3">
				<div v-if="showTitle">
					<h4
						v-if="
							currentRouteName != null &&
							showCurrentRouteName &&
							customTitle == null
						"
						class="text-dark fw-bold"
					>
						{{ currentRouteName }}
					</h4>
					<h4 v-else class="text-dark fw-bold">
						{{ customTitle }}
					</h4>
				</div>
				<div v-if="addButtonText == 'Añadir juego'" class="col-12">
					<div
						class="action-buttons d-flex gap-3 align-items-center justify-content-md-end pb-1 f-right col-4"
					>
						<Button
							:label="addButtonText"
							class="btn-primary text-white mr-custom-12"
							@click="addRegister()"
						>
							<img
								style="margin-right: 10px"
								src="@/assets/images/icons/add_game.svg"
								width="20"
							/>
							{{ addButtonText }}
						</Button>
						<slot name="custom-actions"></slot>
					</div>
				</div>
				<div
					:class="{
						'col-12 col-md-8': type_filter == 0,
						'col-12 col-md-12': type_filter == 1,
					}"
				>
					<TableFilters
						:total_amount_benefit="total_amount_benefit"
						:total_amount="total_amount"
						:type_sale_custom="type_sale_custom"
						@change-value-type-sales="changeValueTypeSales"
						:filters="filters"
						:type_filter="type_filter"
						:addButtonText="addButtonText"
						:stateVariable="stateVariable"
						:total="total"
					/>
				</div>
				<template v-if="type_filter == 0">
					<div
						v-if="addButtonText != '' && addButtonText != 'Añadir juego'"
						class="col-12 col-md-4"
					>
						<div
							class="action-buttons d-flex gap-3 align-items-center justify-content-md-end pb-1"
						>
							<Button
								v-if="
									($helper.checkUserHasPermission(route, 'create') ||
										$route.name === 'Demo') &&
									showAddButton
								"
								:label="addButtonText"
								class="btn-primary text-white"
								@click="addRegister()"
							/>
							<Button
								v-if="
									(selectedIds.length > 0 &&
										$helper.checkUserHasPermission(route, 'delete')) ||
									$route.name === 'Demo'
								"
								label="Borrar seleccionados"
								class="bg-primary text-white"
								icon="pi pi-trash"
								@click="deleteCheckedRegisters()"
							/>
							<slot name="custom-actions"></slot>
						</div>
					</div>
					<div
						class="action-buttons d-flex gap-3 align-items-center justify-content-md-end pb-1 f-right col-4"
					>
						<template v-for="(filter, index) in filters" :key="index">
							<Button
								v-if="filter.name == 'button_filter'"
								:label="'Filtrar'"
								class="btn-primary text-white mr-custom-12"
								@click="filter.value = 1"
							/>
						</template>
						<slot name="custom-actions"></slot>
					</div>
				</template>
			</div>
		</template>

		<template #empty>{{ datatableDefaults.noResults }}</template>

		<Column
			v-if="rowReorder"
			rowReorder
			headerStyle="width: 3rem"
			:reorderableColumn="false"
			header="Reordenar"
		/>

		<template #loading>
			<LoadingTable />
		</template>

		<Column
			v-if="
				($helper.checkUserHasPermission(route, 'delete') ||
					$route.name === 'Demo') &&
				showSelectMultipleColumn
			"
			class="select-multiple"
			selectionMode="multiple"
			headerStyle="width: 3em"
		></Column>

		<slot name="columns"></slot>

		<Column v-if="showActionsColumn" header="Acciones">
			<template #body="slotProps">
				<Button class="table-custom-button-action d-flex gap-3">
					<img
						v-if="
							($helper.checkUserHasPermission(route, 'update') ||
								$route.name === 'Demo') &&
							showEditButton
						"
						src="@/assets/images/icons/edit.svg"
						@click="showRegister(slotProps.data, 'edit')"
					/>

					<img
						v-if="
							($helper.checkUserHasPermission(route, 'delete') ||
								$route.name === 'Demo') &&
							showDeleteButton
						"
						src="@/assets/images/icons/delete.svg"
						@click="deleteRegister(slotProps.data.id)"
					/>
				</Button>
			</template>
		</Column>
	</DataTable>
</template>

<script>
	import DataTable from "primevue/datatable";
	import Column from "primevue/column";
	import LoadingTable from "@/components/partials/tables/LoadingTable.vue";
	import TableFilters from "@/components/partials/tables/TableFilters.vue";

	import { mapState, mapActions, mapMutations } from "vuex";

	export default {
		components: {
			TableFilters,
			DataTable,
			Column,
			LoadingTable,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
			list: {
				type: Array,
				default: () => [],
			},
			total: {
				type: Number,
				default: 0,
			},
			filters: {
				type: Array,
				default: () => [],
			},
			delete: {
				type: String,
			},
			showAddButton: {
				type: Boolean,
				default: true,
			},
			addButtonText: {
				type: String,
				default: "Añadir registro",
			},
			showEditButton: {
				type: Boolean,
				default: true,
			},
			showDeleteButton: {
				type: Boolean,
				default: true,
			},
			showActionsColumn: {
				type: Boolean,
				default: true,
			},
			showCurrentRouteName: {
				type: Boolean,
				default: true,
			},
			reloadByFilters: {
				type: Boolean,
				default: true,
			},
			additionalParams: {
				type: Object,
				default: () => ({}),
			},
			customTitle: {
				type: String,
				default: null,
			},
			rowReorder: {
				type: Boolean,
				default: false,
			},
			showSelectMultipleColumn: {
				type: Boolean,
				default: true,
			},
			showTitle: {
				type: Boolean,
				default: true,
			},
			type_filter: {
				type: Number,
				default: 0,
			},
			total_amount: {
				type: Number,
				default: 0,
			},
			total_amount_benefit: {
				type: Number,
				default: 0,
			},
		},
		data() {
			return {
				selectedIds: [],
				timeout: null,
				lastPageChange: null,
				type_sale: 1,
				type_sale_custom: 1,
			};
		},
		methods: {
			...mapActions(["getRegisters", "deleteRegisters"]),
			...mapMutations([
				"changeCurrentTablePage",
				"toggleFormDialog",
				"changeFormDialogMode",
				"changeCurrentRegister",
				"toggleInfoFaceToFaceDialog",
				"toggleAddSongDialog"
			]),
			//Pasar el valor de type_sales al componente padre
			changeValueTypeSales($event) {
				this.type_sale = $event;
			},
			getList(event = null) {
				if (
					this.$helper.checkUserHasPermission(this.route) ||
					this.$route.name === "Demo"
				) {
					var type = 1;
					if (this.stateVariable == "payments_made") {
						type = 2;
					}
					this.getRegisters({
						route: this.route,
						stateVariable: this.stateVariable,
						page: event?.page,
						rows: event?.rows,
						additionalParams: this.additionalParams,
						type: type,
					});
				} else {
					this.$router.push({ name: "Usuarios" });
				}
			},
			changeCurrentPage(event) {
				this.lastPageChange = event;
				this.datatableDefaults.rows = this.lastPageChange.rows;

				if (event != null) {
					this.changeCurrentTablePage({
						stateVariable: this.stateVariable,
						event,
					});
				}
				var type = 1;
				if (this.stateVariable == "payments_made") {
					type = 2;
				}
				this.getRegisters({
					route: this.route,
					stateVariable: this.stateVariable,
					page: event?.page,
					rows: event?.rows,
					additionalParams: this.additionalParams,
					type: type,
				});

				this.$emit("changeCurrentPage", event);
			},
			addRegister() {
				if(this.addButtonText == "Añadir canción"){
					this.toggleAddSongDialog(true);

				}else{
					this.toggleFormDialog({
						stateVariable: this.stateVariable,
						show: true,
					});
					this.$emit("addRegister");
				}
			},
			showRegister(e, type) {
				const classList = e.originalEvent?.target.classList;
				if(this.stateVariable != 'songs'){
					if (
						(classList == undefined || !classList?.contains("download-link")) &&
						!classList?.contains("p-checkbox-icon")
					) {
						const register = e.data != undefined ? e.data : e;

						this.changeCurrentRegister({
							stateVariable: this.stateVariable,
							register,
						});

						this.changeFormDialogMode({
							stateVariable: this.stateVariable,
							dialogMode: type,
						});

						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							show: true,
						});

						this.$emit("showRegister", register, type);
					}
				}
			},
			deleteRegister(id) {
				this.$confirm.require({
					message: "¿Desea borrar el registro?",
					header: "Borrar registro",
					icon: "pi pi-exclamation-triangle",
					accept: () => {
						this.deleteRegisters({
							url: `/${this.delete}/${id}`,
						}).then(() => {
							this.getList(this.lastPageChange);
							this.$emit("getList", this.lastPageChange);
						});
					},
				});
			},
			deleteCheckedRegisters() {
				let ids = this.$helper.pushIdsToArray(
					this.selectedIds,
					this.lastPageChange != null
						? this.lastPageChange.rows
						: this.datatableDefaults.rows
				);

				this.$confirm.require({
					message: "¿Desea borrar los registros seleccionados?",
					header: "Borrar registros",
					icon: "pi pi-exclamation-triangle",
					accept: () => {
						this.deleteRegisters({
							url: `/${this.delete}-multiple`,
							ids,
						}).then(() => {
							this.getList(this.lastPageChange);

							this.$emit("getList", this.lastPageChange);
							this.selectedIds = [];
						});
					},
				});
			},
			onRowReorder(event) {
				this.$emit("onRowReorder", event);
			},
			firstTwoLetters(name) {
				return name.substring(0, 2);
			},
		},
		computed: {
			...mapState(["datatableDefaults"]),
			currentRouteName() {
				return this.$route.name;
			},
		},
		watch: {
			filters: {
				handler(value) {
					value.forEach((v) => {
						if (
							(v.name == "buscar" && v.value != "" && v.value != null) ||
							(v.name == "button_filter" && v.value == 1) ||
							((v.name == "from" || v.name == "to") && this.addButtonText == "Añadir canción")
						) {
							if (this.reloadByFilters) {
								clearTimeout(this.timeout);

								this.type_sale_custom = this.type_sale;
								this.$emit("change-value-type-sales", this.type_sale);
								this.timeout = setTimeout(() => {
									this.getList();
								}, 250);
							}
						}
					});
				},
				deep: true,
			},
		},
		mounted() {
			this.getList();
		},
	};
</script>

<style lang="scss" scoped>
	.action-buttons {
		button {
			min-width: fit-content;
		}
	}

	@media only screen and (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
		:deep(.p-paginator-current) {
			text-align: center;
		}

		:deep(.select-multiple) {
			display: none !important;
		}

		.action-buttons {
			overflow-x: auto;
		}
	}
	.f-right {
		float: right;
	}

	div.add:before {
		content: "";
		position: absolute;
		right: 20px;
		top: 0;
		bottom: 0;
		width: 22px;
		background: url("@/assets/images/icons/add_user.svg") center / contain
			no-repeat;
	}

	.mr-custom-12 {
		margin-right: 12px;
	}
</style>
