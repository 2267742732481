<template>
	<div>
		<TabView>
			<TabPanel header="Suscripciones activas">
				<SuscriptionsActiveTable />
			</TabPanel>
			<TabPanel header="Pagos realizados">
				<PaymentsMadeTable />
			</TabPanel>
		</TabView>
	</div>
</template>

<script>
	import SuscriptionsActiveTable from "./SuscriptionsActiveTable.vue";
	import PaymentsMadeTable from "./PaymentsMadeTable.vue";
	import TabView from "primevue/tabview";
	import TabPanel from "primevue/tabpanel";

	export default {
		components: {
			SuscriptionsActiveTable,
			PaymentsMadeTable,
			TabView,
			TabPanel,
		},
	};
</script>
<style lang="scss" scoped></style>
