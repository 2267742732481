<template>
	<TableDefault
		ref="table"
		:route="'list-payments'"
		:stateVariable="stateVariable"
		:list="payments_made.list"
		:total="payments_made.listTotal"
		:filters="filters"
		:type_filter="4"
		:showActionsColumn="false"
		:showDeleteButton="false"
		:showTitle="false"
		:addButtonText="''"
		:showSelectMultipleColumn="false"
	>
		<template #columns>
			<Column>
				<template #header>
					<span style="width: 300px !important">NOMBRE</span>
				</template>
				<template #body="slotProps">
					<span style="width: 300px !important">{{ slotProps.data.name }}</span>
				</template>
			</Column>
			<Column>
				<template #header>
					<span style="width: 400px !important">EMAIL</span>
				</template>
				<template #body="slotProps">
					<span v-html="slotProps.data.email" style="width: 400px !important" />
				</template>
			</Column>
			<Column>
				<template #header>
					<span style="width: 400px !important">PRECIO</span>
				</template>
				<template #body="slotProps">
					<span class="fw-700 text-blue-light" style="width: 400px !important">
						{{ slotProps.data.payment }} EUR
					</span>
				</template>
			</Column>
			<Column>
				<template #header>
					<span style="width: 300px !important">FECHA</span>
				</template>
				<template #body="slotProps">
					<span style="width: 300px !important">{{
						$helper.formatDate(slotProps.data.created_at, "DD/MM/YYYY")
					}}</span>
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Column from "primevue/column";
	import { mapState } from "vuex";

	export default {
		components: {
			Column,
		},
		data() {
			return {
				filters: [
					{
						name: "buscar",
						value: null,
						type: "string",
						placeholder: "Buscar...",
					},
					{
						name: "from",
						value: null,
						type: "date",
						placeholder: "Desde",
					},
					{
						name: "to",
						value: null,
						type: "date",
						placeholder: "Hasta",
					},
					{
						name: "email",
						value: null,
						type: "string",
						placeholder: "Filtrar por email",
					},
					{
						name: "button_filter",
						value: 0,
					},
				],
				route: "payments_made",
				stateVariable: "payments_made",
			};
		},
		computed: {
			...mapState(["payments_made"]),
		},
	};
</script>

<style lang="scss" scoped>
	.p-avatar.not-active,
	.rol.not-active {
		background-color: #abafb3 !important;
		color: white !important;
		border-width: 0px;
	}

	.f-12 {
		font-size: 12px;
	}

	.f-14 {
		font-size: 14px;
	}

	.br-8 {
		border-radius: 8px !important;
	}

	.gray-color {
		color: #b5b5c3;
	}

	.fw-500 {
		font-weight: 500;
	}

	.border-1 {
		border-width: 1px;
		border-style: solid;
	}

	.not-active {
		filter: grayscale(100%);
	}

	.fw-700 {
		font-weight: 700;
	}
</style>
