import { createWebHistory, createRouter } from "vue-router";

import axios from "./axios";
import store from "./store";

// Layouts
import AuthLayout from "@/components/layouts/AuthLayout.vue";
import AdminLayout from "@/components/layouts/AdminLayout.vue";
import UserLayout from "@/components/layouts/UserLayout.vue";
import VerifiedAccount from "@/components/auth/VerifiedAccount.vue";

// Admin
import Users from "@/components/users/Users.vue";
import Sales from "@/components/sales/Sales.vue";
import Suscriptions from "@/components/suscriptions/Suscriptions.vue";
import Concerts from "@/components/concerts/Concerts.vue";
import Profile from "@/components/profile/Profile.vue";

// Users
import Songs from "@/components/songs/Songs.vue";

const routes = [
    {
        path: "/",
        component: AuthLayout,
        beforeEnter: checkLogin,
        name: "login",
    },
    {
        path: "/password/reset/:token",
        component: AuthLayout,
        name: "PasswordReset",
    },
    {
        path: "/verfied-account",
        component: VerifiedAccount,
        name: "VerifiedAccount",
    },
    {
        path: "/auth/:provider/callback",
        component: {
            template: '<div class="auth-component"></div>',
        },
    },
    {
        path: "/admin",
        component: AdminLayout,
        beforeEnter: checkUserLogged,
        children: [{
                path: "users",
                name: "Usuarios",
                component: Users,
                meta: {
                    title: "Usuarios",
                },
            },
            {
                path: "sales",
                name: "Ventas",
                component: Sales,
                meta: {
                    title: "Ventas",
                },
            },
            {
                path: "suscriptions",
                name: "Suscripciones",
                component: Suscriptions,
                meta: {
                    title: "Suscripciones",
                },
            },
            {
                path: "concerts",
                name: "Conciertos",
                component: Concerts,
                meta: {
                    title: "Conciertos",
                },
            },
            {
                path: "profile",
                name: "Perfil",
                component: Profile,
                meta: {
                    title: "Perfil",
                },
            },
        ],
    },
    {
        path: "/users",
        component: UserLayout,
        beforeEnter: checkUserLogged,
        children: [{
                path: "songs",
                name: "Canciones",
                component: Songs,
                meta: {
                    title: "Canciones",
                },
            },
        ],
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/",
    },
];

function checkLogin(to, from, next) {
    if (localStorage.getItem("accessToken") != null) {
        next({ path: "/admin/users" });
    } else {
        next();
    }

    return;
}

function checkUserLogged(to, from, next) {
    if (localStorage.getItem("accessToken") == null) {
        next({ path: "/login" });

        return;
    }

    axios.defaults.headers.common[
        "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`;

    axios
        .post("/check-user-logged")
        .then((response) => {
            store.commit("changeUserLogged", response.data.user);

            if (to.path === "/login") {
                if(response.data.user.role_id == 1){
                    next({ path: "/admin/users" });
                }else{
                    next({ path: "/users/songs" });
                }
            } else {
                next();
            }
        })
        .catch((error) => {
            console.error(
                "Router JS ~ checkAdminRights",
                error.response,
                //error.response ? .data ? .message
            );

            if (error.response.data.message === "CSRF token mismatch.") {
                location.reload();
            }

            if (
                error.response.data.message == "User not found" ||
                error.response.data.message == "Unauthorized" ||
                error.response.data.message == "Unauthenticated."
            ) {
                window.localStorage.clear();
                next({ path: "/login" });
            }
        });

    return;
}

const router = new createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    // Get the page title from the route meta data that we have defined
    // See further down below for how we setup this data
    const title = to.meta.title;

    //Take the title from the parameters
    const titleFromParams = to.params.pageTitle;
    // If the route has a title, set it as the page title of the document/page
    if (title) {
        document.title = title;
    }
    // If we have a title from the params, extend the title with the title
    // from our params
    if (titleFromParams) {
        document.title = `${titleFromParams} - ${document.title}`;
    }
    // Continue resolving the route
    next();
});

export default router;