<template>
	<div>
		<SongsTable :route="route" :stateVariable="stateVariable" />
		<SongForm :route="route" :stateVariable="stateVariable" />
		<TracksSongForm :route="route" :stateVariable="stateVariable" />
	</div>
</template>

<script>
	import SongsTable from "./SongsTable.vue";
	import SongForm from "./SongForm.vue";
	import TracksSongForm from "./TracksSongForm.vue";

	export default {
		components: {
			SongsTable,
			SongForm,
			TracksSongForm
		},
		data() {
			return {
				route: "songs",
				stateVariable: "songs",
			};
		},
	};
</script>
