<template>
	<TableDefault
		ref="table"
		:route="route"
		:stateVariable="stateVariable"
		:list="songs.list"
		:total="songs.listTotal"
		:filters="filters"
		:type_filter="0"
		:delete="'songs'"
		:showActionsColumn="false"
		:showDeleteButton="false"
		:showTitle="false"
		:addButtonText="'Añadir canción'"
		:showSelectMultipleColumn="false"
	>
		<template #columns>
			<Column>
				<template #header>
					<span style="width: 200px">NOMBRE</span>
				</template>
				<template #body="slotProps">
					<div class="d-flex align-items-center gap-2" style="width: 200px">
						<b :class="{ 'gray-color': !slotProps.data.active }">{{
							slotProps.data.name
						}}</b>
					</div>
				</template>
			</Column>
			<Column>
				<template #header>
					<span style="width: 200px">FECHA</span>
				</template>
				<template #body="slotProps">
					<span class="gray-color" style="width: 200px">
						{{ $helper.formatDate(slotProps.data.created_at, "DD/MM/YYYY") }}
					</span>
				</template>
			</Column>
			<Column header="">
				<template #body="slotProps">
					<Button class="table-custom-button-action d-flex gap-3" style="width: 200px">
						<img src="@/assets/images/icons/edit.svg" @click="showRegister(slotProps.data.id, slotProps.data.name)" />
						<img src="@/assets/images/icons/delete.svg" @click="deleteRegister(slotProps.data.id, slotProps.data.name)" />
                        <img :id="'player_'+slotProps.data.id" src="@/assets/images/icons/play_icon.svg" @click="play(slotProps.data.id)"/>
                        <audio :id="'audio_'+slotProps.data.id" :src="baseURL + '/files/songs/'+ slotProps.data.id_user + '/' + slotProps.data.name"></audio>
					</Button>
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Column from "primevue/column";
	import { mapState, mapActions, mapMutations } from "vuex";

	export default {
		components: {
			Column,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "buscar",
						value: null,
						type: "string",
						placeholder: "Buscar...",
					},
					{
						name: "from",
						value: null,
						type: "date",
						placeholder: "Desde",
					},
					{
						name: "to",
						value: null,
						type: "date",
						placeholder: "Hasta",
					},
                    /*{
						name: "button_filter",
						value: 0,
					},*/
				],
			};
		},
		computed: {
			...mapState(["songs", "song", "baseURL"]),
		},
		methods: {
			...mapActions(["deleteRegisters", "getInfoSong"]),
			...mapMutations([
				"toggleFormDialog",
				"changeFormDialogMode",
				"changeCurrentRegister",
				"toggleFormDialog",
                "toggleDeleteSongDialog"
			]),
			firstTwoLetters(name) {
				return name.substring(0, 2);
			},
			showRegister(id, name) {
                let me = this;
                me.song.id = id;
                me.song.name = name;
				let params = {
					"id_song": me.song.id
				};
				me.getInfoSong(params);
				me.toggleFormDialog({
					stateVariable: this.stateVariable,
					show: true,
				});
			},
			deleteRegister(id, name) {
                let me = this;
                me.song.id = id;
                me.song.name = name;
				me.toggleDeleteSongDialog(true);
			},
            play(id) {
                var audio = document.getElementById("audio_" + id);
				audio.addEventListener('ended', function() {
					// Puedes realizar acciones adicionales aquí después de que el audio haya terminado de reproducirse.
					document.getElementById("player_" + id).src = require('@/assets/images/icons/play_icon.svg');
				});

				if (!audio.paused) {
					document.getElementById("player_" + id).src = require('@/assets/images/icons/play_icon.svg');
					this.songs.list.map(function(song) {
						document.getElementById("player_" + song.id).src = require('@/assets/images/icons/play_icon.svg');
					});
					audio.pause(); // Detener la reproducción si está en curso
					audio.currentTime = 0;

				} else {
					this.songs.list.map(function(song) {
						document.getElementById("player_" + song.id).src = require('@/assets/images/icons/play_icon.svg');
					});
					audio.pause();
					audio.currentTime = 0;
					document.getElementById("player_" + id).src = require('@/assets/images/icons/stop_icon.svg');
					audio.play(); // Iniciar la reproducción si no se está reproduciendo
				}
            }
		},
	};
</script>

<style lang="scss" scoped>
	.p-avatar.not-active,
	.rol.not-active {
		background-color: #abafb3 !important;
		color: white !important;
		border-width: 0px;
	}

	.gray-color {
		color: #b5b5c3;
	}

	.fw-500 {
		font-weight: 500;
	}

	.border-1 {
		border-width: 1px;
		border-style: solid;
	}

	.not-active {
		filter: grayscale(100%);
	}
</style>
