<template>
	<div>
		<ConcertsTable :route="route" :stateVariable="stateVariable" />
		<ConcertsForm :route="route" :stateVariable="stateVariable" />
	</div>
</template>

<script>
	import ConcertsTable from "./ConcertsTable.vue";
	import ConcertsForm from "./ConcertsForm.vue";

	export default {
		components: {
			ConcertsTable,
			ConcertsForm,
		},
		data() {
			return {
				route: "concerts",
				stateVariable: "concerts",
			};
		},
	};
</script>
