<template>
	<div class="row d-flex w-100 mx-0">
		<div class="col-6 px-0 bg-white-custom">
			<div
				class="auth d-flex flex-column justify-content-center align-items-center py-3 py-md-5"
			>
				<Toast />
				<div
					class="logo d-flex justify-content-center cursor-pointer"
					@click="$router.push({ name: 'home' })"
				>
					<img src="@/assets/images/logo_login.svg" class="mb-3 mb-md-5" />
				</div>
				<div v-if="showForm == 'login'" class="form p-3 p-md-4 p-xxl-5">
					<LoginForm />
				</div>
				<div v-else-if="showForm == 'forgot'" class="form p-3 p-md-4 p-xxl-5">
					<ForgotPasswordForm />
				</div>
				<div v-else class="form p-3 p-md-4 p-xxl-5">
					<ResetPasswordForm />
				</div>
			</div>
		</div>
		<div class="col-6 px-0 bg-image"></div>
	</div>
</template>

<script>
	import LoginForm from "@/components/auth/LoginForm.vue";
	import ForgotPasswordForm from "@/components/auth/ForgotPasswordForm.vue";
	import ResetPasswordForm from "@/components/auth/ResetPasswordForm.vue";
	import Toast from "primevue/toast";

	export default {
		components: {
			LoginForm,
			ForgotPasswordForm,
			ResetPasswordForm,
			Toast,
		},
		data: () => ({
			showForm: "login",
		}),
		methods: {
			showError() {
				this.$toast.add({
					severity: "error",
					summary: "Error",
					detail: "Ha ocurrido un error",
					life: 3000,
				});
			},
			showRegisterAlert() {
				this.$toast.add({
					severity: "success",
					summary: "Registro realizado",
					detail: "Se ha registrado correctamente",
					life: 3000,
				});
			},
			showSendForgotPasswordEmail() {
				this.$toast.add({
					severity: "success",
					summary: "Correo enviado",
					detail: "Se ha enviado el correo de recuperación de contraseña",
					life: 3000,
				});
			},
		},
		computed: {
			isPasswordResetRoute() {
				return this.$route.name === "PasswordReset";
			},
		},
		mounted() {
			if (this.isPasswordResetRoute) {
				this.showForm = "recover";
			}
		},
	};
</script>

<style lang="scss" scoped>
	.auth {
		position: relative;
		z-index: 1;
		min-height: 100vh;

		.image {
			position: absolute;
			z-index: 0;

			&.corner {
				right: 0;
				bottom: 0;
				width: 500px;
				max-width: 80vw;
			}

			&-1 {
				top: 0;
				left: 10%;
			}

			&-2 {
				top: 10rem;
				right: 0;
			}

			&-3 {
				top: 30rem;
				left: 5%;
			}

			&-4 {
				top: 35rem;
				left: 60%;
			}
		}

		.logo {
			z-index: 0;

			img {
				width: 280px;
				height: 176px;
				max-width: 40vw;
			}
		}

		.form {
			position: relative;
			z-index: 1;
			width: 90vw;
			max-width: 600px;
			border-radius: $border-radius;
			/*box-shadow: $smooth-box-shadow;*/
			background-color: white;
			/*backdrop-filter: blur(4px);*/

			.title {
				font-family: initial;
				font-weight: bold;
			}
		}

		@media (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
			.logo {
				img {
					max-width: 50vw;
				}
			}
		}

		@media only screen and (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
			.image {
				width: 250px;
			}

			.logo {
				img {
					width: 200px;
				}
			}
		}
	}
	.bg-image {
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: cover !important;
		background: url("@/assets/images/musicalbase_login.png");
	}

	.bg-white-custom {
		background-color: white;
	}
</style>
