<template>
	<TableDefault
		ref="table"
		:route="route"
		:stateVariable="stateVariable"
		:list="sales.list"
		:total="sales.listTotal"
		:filters="filters"
		:type_filter="1"
		:delete="'sales'"
		:showActionsColumn="false"
		:showDeleteButton="false"
		:showTitle="false"
		:addButtonText="'Exportar'"
		:showSelectMultipleColumn="false"
		@change-value-type-sales="changeValueTypeSales"
		:total_amount="sales.total_amount"
		:total_amount_benefit="sales.total_amount_benefit"
	>
		<template #columns>
			<Column>
				<template #header>
					<span style="width: 200px">NOMBRE</span>
				</template>
				<template #body="slotProps">
					<div class="d-flex align-items-center gap-2" style="width: 200px">
						<Avatar
							class="bg-light-primary text-secondary f-12"
							:label="firstTwoLetters(slotProps.data.name)"
						/>

						<b>{{ slotProps.data.name }}</b>
					</div>
				</template>
			</Column>
			<Column>
				<template #header>
					<span style="width: 400px">EMAIL</span>
				</template>
				<template #body="slotProps">
					<span class="fw-500" style="width: 400px">
						{{ slotProps.data.email }}
					</span>
				</template>
			</Column>
			<Column v-if="type_sale != 2">
				<template #header>
					<span style="width: 200px">ENTRADAS VENDIDAS</span>
				</template>
				<template #body="slotProps">
					<div class="d-flex align-items-center gap-2" style="width: 200px">
						<img src="@/assets/images/icons/icon_tickets.svg" width="20" />
						<span class="fw-700">
							{{ slotProps.data.num_tickets }}
						</span>
					</div>
				</template>
			</Column>
			<Column v-if="type_sale != 2">
				<template #header>
					<span style="width: 200px">CONCIERTOS</span>
				</template>
				<template #body="slotProps">
					<span class="fw-500" style="width: 200px">
						{{ slotProps.data.group }}
					</span>
				</template>
			</Column>
			<Column v-if="type_sale == 2">
				<template #header>
					<span style="width: 200px">HORAS</span>
				</template>
				<template #body="slotProps">
					<div class="d-flex align-items-center gap-2" style="width: 200px">
						<img src="@/assets/images/icons/icon_time.svg" width="18" />
						<span class="gray-color"> {{ slotProps.data.time }} Hrs. </span>
					</div>
				</template>
			</Column>
			<Column v-if="type_sale != 2">
				<template #header>
					<span style="width: 200px">IMPORTE</span>
				</template>
				<template #body="slotProps">
					<span class="fw-700 text-blue-light" style="width: 200px">
						{{ slotProps.data.amount }} EUR
					</span>
				</template>
			</Column>
			<Column v-if="type_sale != 2">
				<template #header>
					<span style="width: 200px">BENEFICIO</span>
				</template>
				<template #body="slotProps">
					<span class="fw-700 text-blue-light" style="width: 200px">
						{{ slotProps.data.benefit }} EUR
					</span>
				</template>
			</Column>
			<Column v-if="type_sale == 2">
				<template #header>
					<span style="width: 200px">IMPORTE</span>
				</template>
				<template #body="slotProps">
					<span class="fw-700 text-blue-light" style="width: 200px">
						{{ slotProps.data.price }} EUR
					</span>
				</template>
			</Column>
			<Column>
				<template #header>
					<span style="width: 200px">FECHA</span>
				</template>
				<template #body="slotProps">
					<span class="gray-color" style="width: 200px">
						{{ $helper.formatDate(slotProps.data.created_at, "DD/MM/YYYY") }}
					</span>
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Avatar from "primevue/avatar";
	import Column from "primevue/column";
	import { mapState, mapActions, mapMutations } from "vuex";

	export default {
		components: {
			Column,
			Avatar,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "buscar",
						value: null,
						type: "string",
						placeholder: "Buscar...",
					},
					{
						name: "email",
						value: null,
						type: "string",
						placeholder: "Filtrar por email",
					},
					{
						name: "type_sale",
						value: null,
						type: "dropdown",
						placeholder: "Tipo de venta",
						options: [
							{
								label: "Entradas",
								value: "1",
							},
							{
								label: "Alquiler de local",
								value: "2",
							},
						],
					},
					{
						name: "from",
						value: null,
						type: "date",
						placeholder: "Desde",
					},
					{
						name: "to",
						value: null,
						type: "date",
						placeholder: "Hasta",
					},
					{
						name: "button_filter",
						value: 0,
					},
				],
				type_sale: 1,
			};
		},
		methods: {
			...mapActions(["deleteRegisters"]),
			...mapMutations([
				"toggleFormDialog",
				"changeFormDialogMode",
				"changeCurrentRegister",
				"toggleInfoDialog",
			]),
			firstTwoLetters(name) {
				return name.substring(0, 2);
			},
			showRegister(e, type, info = false) {
				if (!info) {
					this.$refs.table.showRegister(e, type);
				} else {
					const register = e.data != undefined ? e.data : e;

					this.changeCurrentRegister({
						stateVariable: this.stateVariable,
						register,
					});

					this.toggleInfoDialog(true);
				}
			},
			changeValueTypeSales($event) {
				this.type_sale = $event;
			},
		},
		computed: {
			...mapState(["sales"]),
		},
	};
</script>

<style lang="scss" scoped>
	.p-avatar.not-active,
	.rol.not-active {
		background-color: #abafb3 !important;
		color: white !important;
		border-width: 0px;
	}

	.gray-color {
		color: #b5b5c3;
	}

	.fw-500 {
		font-weight: 500;
	}

	.border-1 {
		border-width: 1px;
		border-style: solid;
	}

	.not-active {
		filter: grayscale(100%);
	}

	.fw-700 {
		font-weight: 700;
	}
</style>
