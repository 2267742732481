<template>
	<TableDefault
		ref="table"
		:route="route"
		:stateVariable="stateVariable"
		:list="users.list"
		:total="users.listTotal"
		:filters="filters"
		:type_filter="1"
		:delete="'users'"
		:showActionsColumn="false"
		:showDeleteButton="false"
		:showTitle="false"
		:addButtonText="'Añadir usuario'"
		:showSelectMultipleColumn="false"
	>
		<template #columns>
			<Column>
				<template #header>
					<span style="width: 200px">NOMBRE</span>
				</template>
				<template #body="slotProps">
					<div class="d-flex align-items-center gap-2" style="width: 200px">
						<Avatar
							:class="{ 'not-active': !slotProps.data.active }"
							class="bg-light-primary text-secondary f-12"
							:label="firstTwoLetters(slotProps.data.name)"
						/>

						<b :class="{ 'gray-color': !slotProps.data.active }">{{
							slotProps.data.name
						}}</b>
					</div>
				</template>
			</Column>
			<Column>
				<template #header>
					<span style="width: 400px">EMAIL</span>
				</template>
				<template #body="slotProps">
					<span
						class="fw-500"
						:class="{ 'gray-color': !slotProps.data.active }"
						style="width: 400px"
					>
						{{ slotProps.data.email }}
					</span>
				</template>
			</Column>
			<Column>
				<template #header>
					<span class="d-flex" style="width: 200px">ROL</span>
				</template>
				<template #body="slotProps">
					<div class="d-flex" style="width: 200px">
						<div
							v-if="slotProps.data.role_id == 1"
							:class="{ 'not-active': !slotProps.data.active }"
							class="bg-blue-light px-3 py-1 text-white br-8 f-12 rol"
						>
							{{ slotProps.data.slug }}
						</div>
						<div
							v-else-if="slotProps.data.role_id == 2"
							:class="{ 'not-active': !slotProps.data.active }"
							class="bg-light-primary border-blue-light text-blue-light px-3 py-1 br-8 f-12 rol border-1"
						>
							{{ slotProps.data.slug }}
						</div>
						<div
							v-else
							:class="{ 'not-active': !slotProps.data.active }"
							class="bg-light-primary text-white px-3 py-1 br-8 f-12 rol"
						>
							{{ slotProps.data.slug }}
						</div>
					</div>
				</template>
			</Column>
			<Column>
				<template #header>
					<span style="width: 200px">VERSIÓN</span>
				</template>
				<template #body="slotProps">
					<div class="d-flex" style="width: 200px">
						<div
							v-if="slotProps.data.version == 1"
							:class="{ 'not-active': !slotProps.data.active }"
							class="bg-light-yellow px-3 py-1 text-yellow br-8 f-12 rol"
						>
							Free
						</div>
						<div
							v-else-if="slotProps.data.version == 2"
							:class="{ 'not-active': !slotProps.data.active }"
							class="bg-light-primary text-primary px-3 py-1 br-8 f-12 rol"
						>
							Premium
						</div>
					</div>
				</template>
			</Column>
			<Column>
				<template #header>
					<span style="width: 200px">FECHA</span>
				</template>
				<template #body="slotProps">
					<span class="gray-color" style="width: 200px">
						{{ $helper.formatDate(slotProps.data.created_at, "DD/MM/YYYY") }}
					</span>
				</template>
			</Column>
			<Column header="">
				<template #body="slotProps">
					<Button
						class="table-custom-button-action d-flex gap-3"
						style="width: 200px"
					>
						<img
							v-if="$helper.checkUserHasPermission(route, 'update')"
							src="@/assets/images/icons/edit.svg"
							@click="showRegister(slotProps.data, 'edit')"
						/>
						<img
							:class="[slotProps.data.active ? '' : 'not-active']"
							src="@/assets/images/icons/block.svg"
							@click="showRegister(slotProps.data, 'block')"
						/>
						<img
							src="@/assets/images/icons/delete.svg"
							@click="showRegister(slotProps.data, 'delete')"
						/>
					</Button>
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Avatar from "primevue/avatar";
	import Column from "primevue/column";
	import { mapState, mapActions, mapMutations } from "vuex";

	export default {
		components: {
			Column,
			Avatar,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "buscar",
						value: null,
						type: "string",
						placeholder: "Buscar...",
					},
					{
						name: "email",
						value: null,
						type: "string",
						placeholder: "Filtrar por email",
					},
					{
						name: "version",
						value: null,
						type: "dropdown",
						placeholder: "Versión",
						options: [
							{
								label: "Free",
								value: "1",
							},
							{
								label: "Premium",
								value: "2",
							},
						],
					},
					{
						name: "from",
						value: null,
						type: "date",
						placeholder: "Desde",
					},
					{
						name: "to",
						value: null,
						type: "date",
						placeholder: "Hasta",
					},
					{
						name: "button_filter",
						value: 0,
					},
				],
			};
		},
		methods: {
			...mapActions(["deleteRegisters"]),
			...mapMutations([
				"toggleFormDialog",
				"changeFormDialogMode",
				"changeCurrentRegister",
				"toggleInfoDialog",
			]),
			firstTwoLetters(name) {
				return name.substring(0, 2);
			},
			showRegister(e, type, info = false) {
				if (!info) {
					this.$refs.table.showRegister(e, type);
				} else {
					const register = e.data != undefined ? e.data : e;

					this.changeCurrentRegister({
						stateVariable: this.stateVariable,
						register,
					});

					this.toggleInfoDialog(true);
				}
			},
		},
		computed: {
			...mapState(["users"]),
		},
	};
</script>

<style lang="scss" scoped>
	.p-avatar.not-active,
	.rol.not-active {
		background-color: #abafb3 !important;
		color: white !important;
		border-width: 0px;
	}

	.gray-color {
		color: #b5b5c3;
	}

	.fw-500 {
		font-weight: 500;
	}

	.border-1 {
		border-width: 1px;
		border-style: solid;
	}

	.not-active {
		filter: grayscale(100%);
	}
</style>
