<template>
	<router-link
		:to="item.href"
		class="link d-flex align-items-center p-3 gap-2 p-ripple"
		v-ripple
	>
		<div class="d-inline-flex m-auto" style="">
			<div style="width: 96px; height: 96px; display: inline-grid">
				<div class="mx-auto mt-auto">
					<img :src="image" width="24" />
				</div>
				<p class="custom-line-heigth mb-auto mx-auto f-14">
					{{ item.label }}
				</p>
			</div>
		</div>
	</router-link>
</template>

<script>
	export default {
		props: ["item"],
		computed: {
			image() {
				const img = this.item.active
					? this.item.permission + "-selected"
					: this.item.permission;
				return require(`@/assets/images/sidebar/${img}.svg`);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.link {
		border-radius: 0px !important;
		text-decoration: none;
		transition: $transition;
		color: #b5b5c3;
		border-radius: $border-radius;

		.custom-line-heigth {
			line-height: 24px;
		}

		i {
			line-height: 24px;
			transform: translateY(-1px);
		}

		&.router-link-active > div.d-inline-flex.m-auto {
			flex-direction: column;
			background-color: #fff;
			border-radius: 10px;
			color: #14a5ff;
		}

		&:hover > div.d-inline-flex.m-auto {
			background-color: #fff;
			border-radius: 10px;
			color: #14a5ff;
		}

		position: relative;
	}
	.f-14 {
		font-size: 14px;
	}

	.ml-2 {
		margin-left: 1rem;
	}

	.logo {
		width: 24px;
		height: 24px;
		text-align: center;
	}
</style>
