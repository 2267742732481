<template>
	<div
		class="auth d-flex flex-column justify-content-center align-items-center py-3 py-md-5 bg-image"
	>
		<div class="logo d-flex justify-content-center cursor-pointer">
			<img src="@/assets/images/logo_login.svg" class="mb-3 mb-md-5" />
		</div>
		<div class="form p-3 p-md-4 p-xxl-5">
			<form>
				<div class="row text-align-center">
					<div class="col-12 h2 text-blue title mb-3 mb-md-1">
						<b>Correo verificado correctamente</b>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({}),
		methods: {},
		mounted() {},
	};
</script>
<style lang="scss" scoped>
	.text-blue {
		color: #218bf4;
	}

	.auth {
		position: relative;
		z-index: 1;
		min-height: 100vh;

		.image {
			position: absolute;
			z-index: 0;

			&.corner {
				right: 0;
				bottom: 0;
				width: 500px;
				max-width: 80vw;
			}

			&-1 {
				top: 0;
				left: 10%;
			}

			&-2 {
				top: 10rem;
				right: 0;
			}

			&-3 {
				top: 30rem;
				left: 5%;
			}

			&-4 {
				top: 35rem;
				left: 60%;
			}
		}

		.logo {
			z-index: 0;

			img {
				width: 280px;
				height: 176px;
				max-width: 40vw;
			}
		}

		.form {
			position: relative;
			z-index: 1;
			width: 90vw;
			max-width: 600px;
			border-radius: $border-radius;
			box-shadow: $smooth-box-shadow;
			background-color: white;
			/*backdrop-filter: blur(4px);*/
			border: 3px solid #ff3c00;

			.title {
				font-family: initial;
				font-weight: bold;
			}
		}

		@media (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
			.logo {
				img {
					max-width: 50vw;
				}
			}
		}

		@media only screen and (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
			.image {
				width: 250px;
			}

			.logo {
				img {
					width: 200px;
				}
			}
		}
	}

	.bg-image {
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: cover !important;
		background: url("@/assets/images/spiral.svg"),
			radial-gradient(
				65.95% 118.97% at 50.85% -7.54%,
				#00a6ff 0%,
				#018fef 29%,
				#0654c8 91%,
				#074bc2 100%
			);
	}
</style>
