<template>
	<div
		class="d-none d-md-flex align-items-center text-white h4 mb-0 px-2"
		style="font-weight: 400 !important"
	>
		Hoy,&nbsp;&nbsp;<b>{{
			new Date().toLocaleString("es-ES", { month: "long", day: "2-digit" })
		}}</b>
	</div>
</template>
