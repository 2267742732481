<template>
	<Dialog
		v-model:visible="$store.state.addSongDialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<FormTitle :title="title" />
		</template>

		<form>
			<div class="row gy-3 pt-3">
				<div class="col-12">
                    <FileUpload
						label="Imagen"
						ref="FileUpload"
						:accept="'.mp3'"
					/>
				</div>
			</div>
		</form>
        <template #footer>
			<div class="col-12 d-flex justify-content-between">
				<Button
					@click="
						toggleAddSongDialog(false)
					"
					label="Cancelar"
					class="bg-white border border-primary text-primary"
				/>
				<Button @click="save()" label="Guardar"/>
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Form from "vform";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data: () => ({
			form: new Form(),
			title: `Añadir canción`,
		}),
		methods: {
			...mapActions(["addSong"]),
			...mapMutations(["toggleAddSongDialog"]),
            save(){
                if (this.$refs.FileUpload != undefined) {
					this.form.song = this.$refs.FileUpload.files[0];
				}

                this.addSong({
                    url: `/songs`,
                    params: this.form
                }).then(() => {
                    this.toggleAddSongDialog(false);
                });
            }
		},
		computed: {
			...mapState(["dialogDefaults"]),
		},
	};
</script>
