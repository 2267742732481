<template>
	<Dialog
		v-model:visible="$store.state.users.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '25vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<FormTitle :title="title" />
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			<div
				class="row gy-3 pt-1"
				v-if="users.dialogMode != 'delete' && users.dialogMode != 'block'"
			>
				<div
					class="col-12 text-align-center align-items-center gap-2"
					v-if="users.dialogMode == 'edit'"
				>
					<Avatar
						style="width: 84px; height: 84px"
						class="bg-light-primary text-primary border-radius f-24 mx-auto br-20"
						:label="label_avatar"
					/>
					<div class="mt-3">
						<span class="text-black">{{ form.name }}</span>
					</div>
					<div class="my-1">
						<span class="f-14 text-dark-grey">{{ form.email }}</span>
					</div>
				</div>
				<div class="col-12">
					<InputText
						ref="name"
						label="Nombre *"
						:disabled="disabled"
						:error="form.errors.get('name')"
						class="bg-inputs"
						:placeholder="'Introducir nombre'"
						@change-value="(value) => (form.name = value)"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="email"
						label="Email *"
						:disabled="disabled"
						:error="form.errors.get('email')"
						class="bg-inputs"
						:placeholder="'Introducir email'"
						@change-value="(value) => (form.email = value)"
					/>
				</div>
				<div class="col-12">
					<div>
						<label style="color: #000b33 !important">Rol *</label>
						<div class="d-flex flex-wrap mr-2 mt-2">
							<div
								class="w-50"
								v-for="(rol, rolIndex) in roles.list"
								:key="rolIndex"
							>
								<div
									class="flex align-items-center bg-radio mr-4 pyc-17 br-8 text-grey"
									:class="{ selected: checked == rol.id }"
								>
									<RadioButton
										v-if="disabled"
										disabled
										:error="form.errors.get('role_id')"
										@change-value="(value) => (form.role_id = value)"
										v-model="checked"
										class="ml-4"
										inputId="{{ rol.name }}"
										ref="role_id"
										:value="rol.id"
									/>
									<RadioButton
										v-else
										:error="form.errors.get('role_id')"
										@change-value="(value) => (form.role_id = value)"
										v-model="checked"
										class="ml-4"
										inputId="{{ rol.name }}"
										ref="role_id"
										:value="rol.id"
									/>
									<label for="{{ rol.name }}" class="ml-2 w-50">{{
										rol.slug
									}}</label>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12">
					<div>
						<label style="color: #000b33 !important">Versión *</label>
						<div class="d-flex flex-wrap mr-2 mt-2">
							<div
								class="w-50"
								v-for="(version, versionIndex) in array_versions"
								:key="versionIndex"
							>
								<div
									class="flex align-items-center bg-radio mr-4 pyc-17 br-8 text-grey"
									:class="{ selected: checked_version == version.id }"
								>
									<RadioButton
										v-if="disabled"
										disabled
										:error="form.errors.get('version')"
										@change-value="(value) => (form.version = value)"
										v-model="checked_version"
										class="ml-4"
										inputId="{{ version.name }}"
										ref="version"
										:value="version.id"
									/>
									<RadioButton
										v-else
										:error="form.errors.get('version')"
										@change-value="(value) => (form.version = value)"
										v-model="checked_version"
										class="ml-4"
										inputId="{{ version.name }}"
										ref="version"
										:value="version.id"
									/>
									<label for="{{ version.name }}" class="ml-2 w-50">{{
										version.name
									}}</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else class="row gy-3 pt-1 mb-2">
				<div class="col-12 text-align-center align-items-center gap-2">
					<img src="@/assets/images/icons/alert_red.svg" />
					<div class="mt-24">
						<span v-if="users.dialogMode == 'delete'" class="text-black"
							>Vas a eliminar al siguiente usuario:</span
						>
						<span
							v-if="users.dialogMode == 'block' && users.register.active == 1"
							class="text-black"
							>Vas a bloquear al siguiente usuario:</span
						>
						<span
							v-if="users.dialogMode == 'block' && users.register.active == 0"
							class="text-black"
							>Vas a desbloquear al siguiente usuario:</span
						>
					</div>
					<div class="mt-24 d-inline-flex">
						<Avatar
							style="width: 48px; height: 48px"
							class="bg-light-primary text-primary border-radius f-16 br-10"
							:label="label_avatar"
						/>
						<div class="ml-8">
							<div class="mr-auto" style="float: left">
								<span class="text-black">{{ form.name }}</span>
							</div>
							<div class="my-1">
								<span class="f-14 text-dark-grey">{{ form.email }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>

		<template #footer>
			<div
				v-if="
					!disabled ||
					(users.dialogMode != 'delete' &&
						users.dialogMode != 'block' &&
						users.dialogMode != 'show')
				"
				class="col-12 d-flex justify-content-between"
			>
				<Button
					@click="
						toggleFormDialog({
							stateVariable: stateVariable,
							value: false,
						})
					"
					label="Cancelar"
					:disabled="form.busy"
					class="bg-white border btn-secundary"
				/>
				<Button
					@click="save()"
					class="btn-primary"
					:label="label_button"
					:disabled="form.busy"
				/>
			</div>

			<div
				class="col-12 d-flex justify-content-between"
				v-if="users.dialogMode == 'delete' || users.dialogMode == 'block'"
			>
				<Button
					@click="
						toggleFormDialog({
							stateVariable: stateVariable,
							value: false,
						})
					"
					label="Cancelar"
					:disabled="form.busy"
					class="bg-white border btn-secundary"
				/>
				<Button
					@click="deleteBlock()"
					class="btn-red"
					:label="label_button"
					:disabled="form.busy"
				/>
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Avatar from "primevue/avatar";
	import RadioButton from "primevue/radiobutton";
	import Form from "vform";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			RadioButton,
			Avatar,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data: () => ({
			form: new Form(),
			modelName: "usuario",
			title: `Añadir usuario`,
			disabled: false,
			lastPageChange: null,
			checked: 1,
			checked_version: 1,
			label_button: "Añadir",
			label_avatar: "",
			array_versions: [
				{
					id: 1,
					name: "Free",
				},
				{
					id: 2,
					name: "Premium",
				},
			],
		}),
		methods: {
			...mapActions([
				"sendForm",
				"getRegisters",
				"deleteRegisters",
				"blockUser",
			]),
			...mapMutations(["toggleFormDialog", "changeCurrentRegister"]),
			save() {
				const update = this.users.register != null;
				const url = `/users${update ? `/${this.users.register.id}` : ""}`;
				this.form.role_id = this.checked;
				this.form.version = this.checked_version;

				this.sendForm({
					method: update ? "put" : "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							value: false,
						});
						var type = 1;
						if (this.stateVariable == "payments_made") {
							type = 2;
						}
						this.getRegisters({
							route: this.route,
							stateVariable: this.stateVariable,
							page: this.users.currentPage,
							rows: this.users.rows,
							type: type,
						});
					}
				});
			},
			hide() {
				this.title = `Añadir ${this.modelName}`;
				this.label_button = "Añadir";
				this.disabled = false;
				this.checked = 1;
				this.checked_version = 1;
				this.users.dialogMode = "add";

				this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					register: null,
				});
			},
			clearForm() {
				this.form.clear();
				this.form.reset();

				if (
					this.users.dialogMode != "delete" &&
					this.users.dialogMode != "block"
				) {
					for (const key in this.$refs) {
						if (Object.hasOwnProperty.call(this.$refs, key)) {
							this.$refs[key].model = null;
						}
					}
				}
			},
			show() {
				this.clearForm();
				var type = 1;
				if (this.stateVariable == "payments_made") {
					type = 2;
				}
				this.getRegisters({
					route: "/roles",
					stateVariable: "roles",
					getAll: true,
					showLoading: false,
					type: type,
				});

				const register = this.users.register;

				if (register != null) {
					for (const key in register) {
						if (this.$refs[key] != undefined) {
							this.$refs[key].model = register[key];
						}
					}
					if (
						this.users.dialogMode == "edit" ||
						this.users.dialogMode == "delete" ||
						this.users.dialogMode == "block"
					) {
						this.label_avatar = this.firstTwoLetters(register.name);
						if (
							this.users.dialogMode == "delete" ||
							this.users.dialogMode == "block"
						) {
							this.form.name = register.name;
							this.form.email = register.email;
						}
					}

					this.checked = register.role_id;
					this.checked_version = register.version;

					if (this.users.dialogMode == "delete") {
						this.title = `Eliminar usuario`;
						this.label_button = "Eliminar";
						this.disabled = true;
					} else if (this.users.dialogMode == "block") {
						if (this.users.register.active == 1) {
							this.title = `Bloquear usuario`;
							this.label_button = "Bloquear";
						} else {
							this.title = `Desbloquear usuario`;
							this.label_button = "Desbloquear";
						}

						this.disabled = true;
					} else if (this.users.dialogMode == "edit") {
						this.title = `Modificar ${this.modelName}`;
						this.label_button = "Guardar";
						this.disabled = false;
					} else {
						this.title = `Ver ${this.modelName}`;
						this.disabled = true;
					}
				}
			},
			firstTwoLetters(name) {
				if (name !== undefined) {
					return name.substring(0, 2);
				}
			},
			deleteBlock() {
				if (this.users.dialogMode == "block") {
					this.blockUser({
						url: `/block-user/${this.users.register.id}`,
					}).then(() => {
						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							value: false,
						});
						var type = 1;
						if (this.stateVariable == "payments_made") {
							type = 2;
						}
						this.getRegisters({
							route: this.route,
							stateVariable: this.stateVariable,
							page: this.users.currentPage,
							rows: this.users.rows,
							type: type,
						});
					});
				} else if (this.users.dialogMode == "delete") {
					this.deleteRegisters({
						url: `/users/${this.users.register.id}`,
					}).then(() => {
						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							value: false,
						});
						var type = 1;
						if (this.stateVariable == "payments_made") {
							type = 2;
						}
						this.getRegisters({
							route: this.route,
							stateVariable: this.stateVariable,
							page: this.users.currentPage,
							rows: this.users.rows,
							type: type,
						});
					});
				}
			},
		},
		computed: {
			...mapState(["dialogDefaults", "users", "roles"]),
		},
	};
</script>
<style lang="scss" scoped>
	.ml-2 {
		margin-left: 0.5rem;
	}

	.mr-4 {
		margin-right: 1rem;
	}

	.ml-4 {
		margin-left: 1rem;
	}

	.pyc-17 {
		padding-top: 17px;
		padding-bottom: 17px;
	}

	.br-8 {
		border-radius: 8px;
	}

	.br-8.selected {
		border-width: 1px;
		border-color: $primary;
		border-style: solid;
		color: #464e5f !important;
	}

	.br-20 {
		border-radius: 20px;
	}

	.text-primary {
		color: #0c6094 !important;
	}

	.btn-red,
	.btn-red:hover {
		color: #ffffff;
		background: #ff0c51 !important;
	}
</style>
