<template>
	<div
		class="sidebar d-flex flex-wrap align-content-between bg-color border-right h-100"
	>
		<SidebarLinks />
		<div class="px-3 mb-3 w-100">
			<Logout class="w-100" />
		</div>
	</div>
</template>

<script>
	import SidebarLinks from "@/components/partials/SidebarLinks.vue";
	import Logout from "@/components/panels/Logout.vue";

	export default {
		components: {
			SidebarLinks,
			Logout,
		},
	};
</script>

<style lang="scss" scoped>
	.sidebar {
		height: 80%;

		.p-submenu-header {
			background-color: transparent;
			color: #1e1e1e;
			text-transform: uppercase;
		}

		.p-menuitem {
			.p-menuitem-link {
				transition: $transition;

				&:not(.p-disabled) {
					&:hover {
						background: rgba(white, 0.3);

						span {
							color: white;
						}
					}
				}

				span {
					color: white;
				}

				&:focus {
					box-shadow: none;
				}

				&:hover {
					background: rgba(white, 0.3);
				}
			}
		}
	}

	.bg-color {
		background-color: #f6f6f6;
	}

	.border-right {
		border-right-width: 1px;
		border-right-style: solid;
		border-right-color: #d9dbe4;
	}
</style>
