<template>
	<div>
		<div class="card">
			<div class="head">
				<div class="elipse">
					<div class="avatar mx-auto d-flex">
						<span class="mx-auto my-auto name-user">DR</span>
					</div>
				</div>
			</div>
			<div class="content mx-auto">
				<div class="fullname-user">
					<span>{{ userLogged.name }}</span>
				</div>
				<div class="email-user">
					<span>{{ userLogged.email }}</span>
				</div>
				<div
					class="bg-blue-light py-1 text-white br-8 f-12 text-align-center d-inline mx-auto d-table mt-8"
				>
					<span v-if="userLogged.role_id == 1" class="px-3">Admin</span>
					<span v-else class="px-3">Usuario</span>
				</div>
				<div class="member mt-8">
					<span>Miembro desde:</span>
				</div>
				<div class="member">
					<span>{{
						$helper.formatDate(userLogged.created_at, "DD/MM/YYYY")
					}}</span>
				</div>
				<div class="text-align-center mt-8">
					<Button
						@click="toggleUpdatePasswordDialog(true)"
						label="Modificar contraseña"
						class="bg-blue-light text-white border btn-secundary"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapMutations, mapState } from "vuex";

	export default {
		components: {},
		data() {
			return {
				route: "profile",
				stateVariable: "profile",
				date_member: "",
			};
		},
		computed: {
			...mapState(["userLogged"]),
		},
		methods: {
			...mapMutations(["toggleUpdatePasswordDialog"]),
		},
		mounted() {
			var date = new Date(this.userLogged.created_at);

			//var result2 = "" + date.getFullYear() + ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1) + (date.getDate() > 9 ? '' : '0') + date.getDate();
			this.date_member =
				"" +
				(date.getDate() > 9 ? "" : "0") +
				date.getDate() +
				"/" +
				(date.getMonth() + 1 > 9 ? "" : "0") +
				(date.getMonth() + 1) +
				(date.getDate() > 9 ? "" : "0") +
				"/" +
				date.getFullYear();
		},
	};
</script>

<style lang="scss" scoped>
	.card {
		width: 30em;
		border-width: 0px;
		box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
	}

	.head {
		border-radius: 8px 8px 0px 0px;
		background: var(--primary, #0c6094);
		height: 214px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.content {
		height: 350px;
	}

	.elipse {
		position: absolute;
		margin-top: 205px;
		width: 115px;
		height: 115px;
		border-radius: 50%;
		background-color: #fff;
	}

	.avatar {
		border-radius: 100px;
		border: 4px solid var(--primary, #0c6094);
		background: rgba(20, 165, 255, 0.15);
		box-shadow: 0px 0px 0px 9px #fff inset;
		height: 100px;
		width: 100px;
		margin-top: 7px;
	}

	.name-user {
		color: var(--blue-light, #14a5ff);
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		letter-spacing: 0.72px;
	}

	.fullname-user {
		margin-top: 60px;
		color: var(--text-default, #000b33);
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		text-align: center;
	}

	.email-user {
		color: var(--grey, #656575);
		font-size: 15px;
		font-style: normal;
		font-weight: 500;
		line-height: 25.5px;
		letter-spacing: 0.5px;
		text-align: center;
		margin-top: 5px;
	}

	.mt-8 {
		margin-top: 8px;
	}

	.mt-20 {
		margin-top: 20px;
	}

	.member {
		text-align: center;
		font-size: 14px;
		color: #a1a5b7;
		font-weight: 400;
	}
</style>
