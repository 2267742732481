<template>
	<Dialog
		v-model:visible="$store.state.concerts.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '25vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<FormTitle :title="title" />
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			<div class="row gy-3 pt-1">
				<div class="col-12 text-align-center align-items-center gap-2">
					<img
						style="width: 84px; height: 84px"
						class="bg-light-primary text-primary border-radius f-24 mx-auto br-20"
						src="@/assets/images/icons/icon_group.svg"
					/>
					<div class="mt-3">
						<span class="text-black">{{ concerts.register.user.name }}</span>
					</div>
				</div>
				<div class="col-12 gap-24">
					<div>
						<span class="f-700 text-black">Email</span>
					</div>
					<div class="gap-8">
						<span class="f-400 text-light-gray">{{
							concerts.register.user.email
						}}</span>
					</div>
				</div>
				<div class="col-12 gap-24">
					<div>
						<span class="f-700 text-black">Nombre del grupo</span>
					</div>
					<div class="gap-8">
						<span class="f-400 text-light-gray">{{
							concerts.register.group
						}}</span>
					</div>
				</div>
				<div class="col-12 gap-24">
					<div>
						<span class="f-700 text-black">Total entradas</span>
					</div>
					<div class="gap-8">
						<span class="f-400 text-light-gray">{{
							concerts.register.num_tickets
						}}</span>
					</div>
				</div>
				<div class="col-12 gap-24">
					<div>
						<span class="f-700 text-black">Fecha de actuación</span>
					</div>
					<div class="gap-8">
						<span class="f-400 text-light-gray">{{
							$helper.formatDate(
								concerts.register.performance_date,
								"DD/MM/YYYY"
							)
						}}</span>
					</div>
				</div>
				<div class="col-12 gap-24">
					<div>
						<span class="f-700 text-black">Trayectoria del grupo</span>
					</div>
					<div class="gap-8 bg-span br-8">
						<span class="f-400 text-light-gray notes">{{
							concerts.register.history
						}}</span>
					</div>
				</div>
				<div class="col-12 gap-24">
					<div>
						<span class="f-700 text-black">Precio</span>
					</div>
					<div class="gap-8">
						<InputText
							v-model="price"
							:disabled="disabled || concerts.register.status == 1"
							class="gap-8 w-100 f-400 text-light-gray notes bg-span"
						/>
					</div>
				</div>

				<div class="col-12">
					<div>
						<span class="f-700 text-black">Comentarios</span>
					</div>
					<div class="gap-8 bg-span br-8">
						<Textarea
							v-model="notes"
							autoResize
							rows="5"
							class="gap-8 w-100 f-400 text-light-gray notes bg-span"
						/>
					</div>
				</div>
			</div>
		</form>

		<template #footer>
			<div class="col-12 d-flex justify-content-between">
				<Button
					@click="save()"
					class="btn-primary mx-auto"
					:label="label_button"
				/>
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Form from "vform";
	import Textarea from "primevue/textarea";
	import InputText from "primevue/inputtext";
	import { mapMutations, mapState, mapActions } from "vuex";

	export default {
		components: {
			Textarea,
			InputText,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data: () => ({
			form: new Form(),
			modelName: "concerts",
			title: `Solicitud`,
			disabled: false,
			lastPageChange: null,
			label_button: "Actualizar",
			notes: "",
		}),
		methods: {
			...mapActions(["sendForm", "getRegisters"]),
			...mapMutations(["toggleFormDialog", "changeCurrentRegister"]),
			save() {
				this.form.id_concert = this.concerts.register.id;
				this.form.notes = this.notes;
				this.form.price = this.price;

				this.sendForm({
					method: "post",
					form: this.form,
					url: "/update_concert",
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							value: false,
						});

						this.getRegisters({
							route: this.route,
							stateVariable: this.stateVariable,
							page: this.concerts.currentPage,
							rows: this.concerts.rows,
						});
					} else {
						this.$toast.add({
							severity: "error",
							summary: "Error",
							detail: "Debe indicar un precio que sea un número y mayor a 0",
							life: 3000,
						});
					}
				});
			},
			hide() {
				this.title = `Solicitud`;
				this.label_button = "Actualizar";
				this.disabled = false;

				this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					register: null,
				});
			},
			clearForm() {
				this.form.clear();
				this.form.reset();

				for (const key in this.$refs) {
					if (Object.hasOwnProperty.call(this.$refs, key)) {
						this.$refs[key].model = null;
					}
				}
			},
			show() {
				this.clearForm();

				const register = this.concerts.register;
				this.notes = this.concerts.register.notes;
				this.price = this.concerts.register.price;

				if (register != null) {
					for (const key in register) {
						if (this.$refs[key] != undefined) {
							this.$refs[key].model = register[key];
						}
					}

					this.title = `Solicitud`;
				}
			},
			firstTwoLetters(name) {
				if (name !== undefined) {
					return name.substring(0, 2);
				}
			},
		},
		computed: {
			...mapState(["dialogDefaults", "concerts"]),
		},
	};
</script>
<style lang="scss" scoped>
	.ml-2 {
		margin-left: 0.5rem;
	}

	.mr-4 {
		margin-right: 1rem;
	}

	.ml-4 {
		margin-left: 1rem;
	}

	.pyc-17 {
		padding-top: 17px;
		padding-bottom: 17px;
	}

	.br-8 {
		border-radius: 8px;
	}

	.br-8.selected {
		border-width: 1px;
		border-color: $primary;
		border-style: solid;
		color: #464e5f !important;
	}

	.br-20 {
		border-radius: 20px;
	}

	.text-primary {
		color: #0c6094 !important;
	}

	.btn-red,
	.btn-red:hover {
		color: #ffffff;
		background: #ff0c51 !important;
	}

	.f-400 {
		font-weight: 400;
	}

	.f-700 {
		font-weight: 700;
	}

	.gap-8 {
		gap: 8px;
	}

	.gap-24 {
		gap: 24px;
	}

	.notes {
		display: flex;
		padding: 8px 16px;
		align-items: flex-start;
		gap: 8px;
		align-self: stretch;
	}

	.bg-span {
		background: var(--border, rgba(217, 219, 228, 0.5)) !important;
	}
</style>
