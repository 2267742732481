<template>
	<div>
		<label for="input" class="text-primary" style="margin-bottom: 16px">{{
			label
		}}</label>

		<InputText
			v-model="model"
			:disabled="disabled"
			:placeholder="placeholder"
			type="text"
			class="w-100 radius-8"
			:class="[error != null ? 'p-invalid' : '']"
		/>

		<div v-if="error != null" class="text-danger">
			{{ error }}
		</div>
	</div>
</template>

<script>
	import InputText from "primevue/inputtext";

	export default {
		components: {
			InputText,
		},
		props: {
			label: {
				type: String,
			},
			placeholder: {
				type: String,
			},
			error: {
				type: String,
				default: null,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			isNumber: {
				type: Boolean,
				default: false,
			},
		},
		data: () => ({
			model: null,
		}),
		watch: {
			model(newValue) {
				let value = null;

				if (this.isNumber && !isNaN(parseInt(newValue))) {
					value = parseInt(newValue);
				} else {
					value = newValue;
				}

				this.$emit("change-value", value);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.text-primary {
		color: #464e5f !important;
	}

	.radius-8 {
		border-radius: 8px;
	}
</style>
