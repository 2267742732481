<template>
	<Dialog
		v-model:visible="$store.state.deleteSongDialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '25vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
	>
		<template #header>
			<FormTitle :title="'Eliminar canción'" />
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			<div class="row gy-3 pt-3">
                
                <div
					class="col-12 text-align-center align-items-center gap-2"
				>
                    <img src="@/assets/images/icons/alert_red.svg" />
					<div class="mt-3">
						<span class="text-black">Vas a eliminar la siguiente canción:</span>
					</div>
					<div class="my-1">
						<span class="f-14 text-dark-grey">{{ song.name }}</span>
					</div>
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-between">
				<Button
					@click="toggleDeleteSongDialog(false)"
					label="Cancelar"
					class="bg-white border btn-secundary"
				/>
				<Button
					@click="deleteSongMethod()"
					class="btn-primary"
					label="Eliminar"
				/>
			</div>
		</template>
	</Dialog>
</template>

<script>
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		data: () => ({
			disabled: false,
			lastPageChange: null,
		}),
		methods: {
			...mapActions(["deleteSong"]),
			...mapMutations(["toggleDeleteSongDialog"]),
			deleteSongMethod() {
                this.deleteSong({
                    url: `/songs/`+this.song.id
                }).then(() => {
                    this.toggleDeleteSongDialog(false);
                });
			},
            
			hide() {
				this.disabled = false;
			},
		},
		computed: {
			...mapState(["dialogDefaults", "deleteSongDialog", "song"]),
		},
	};
</script>

<style lang="scss" scoped>
	.fw-400 {
		font-weight: 400;
	}
</style>
