<template>
	<form
		v-if="type_filter != 4"
		id="filters"
		:class="{ row: type_filter == 0 }"
		class="align-items-center gap-3 pb-1"
	>
		<template v-if="type_filter == 0">
			<div v-for="(filter, index) in filters" :key="index" class="filter col-3">
				<template v-if="filter.name != 'button_filter'">
					<div
						v-if="filter.type == 'string'"
						v-tooltip.top="filter.placeholder"
					>
						<span
							v-if="filter.name == 'buscar'"
							class="p-input-icon-left search w-100 bg-inputs"
						>
							<InputText
								:name="filter.name"
								v-model="filter.value"
								:placeholder="filter.placeholder"
								class="br-8 col-3 w-100"
								@change="test()"
							/>
						</span>
						<span
							v-else-if="filter.name == 'email'"
							class="p-input-icon-left email bg-inputs w-100"
						>
							<InputText
								:name="filter.name"
								v-model="filter.value"
								:placeholder="filter.placeholder"
								class="br-8 col-3"
							/>
						</span>
						<span v-else class="p-input-icon-left bg-inputs w-100">
							<InputText
								:name="filter.name"
								v-model="filter.value"
								:placeholder="filter.placeholder"
								class="br-8 col-3"
							/>
						</span>
					</div>
					<div
						v-if="filter.type == 'dropdown'"
						v-tooltip.top="filter.placeholder"
					>
						<Dropdown
							v-model="filter.value"
							:options="filter.options"
							optionLabel="label"
							optionValue="value"
							:placeholder="filter.placeholder"
							:showClear="true"
							class="br-8 bg-inputs col-3 w-100"
						/>
						<input
							hidden
							type="text"
							:name="filter.name"
							:value="filter.value"
						/>
					</div>
					<div
						class="p-input-icon-left calendar w-100"
						v-if="filter.type == 'date'"
						v-tooltip.top="filter.placeholder"
					>
						<Calendar
							:name="filter.name"
							v-model="filter.value"
							dateFormat="dd/mm/yy"
							:placeholder="filter.placeholder"
							:manualInput="false"
							showButtonBar
							class="w-100 bg-inputs-div bg-inputs br-8 text-dark-blue"
							styles="border-radius: 25px;"
							inputClass="w-100 text-dark-blue"
							inputStyle="border-radius: 25px; padding-left: 0px; color: #0C6094 !important;"
						/>
						<input
							hidden
							type="text"
							:name="filter.name"
							:value="filter.value"
							@onChange="test()"

						/>
					</div>
				</template>
			</div>
		</template>
		<template v-else-if="type_filter == 1 || type_filter == 2">
			<div class="col-12 row">
				<span class="p-input-icon-left search col-6 bg-inputs">
					<template v-for="(filter, index) in filters" :key="index">
						<InputText
							v-if="filter.name == 'buscar'"
							v-model="filter.value"
							:name="filter.name"
							:placeholder="filter.placeholder"
							class="br-8 w-100"
						/>
					</template>
				</span>
				<div
					class="action-buttons d-flex gap-3 align-items-center justify-content-md-end pb-1 f-right col-6 add"
					v-if="addButtonText != ''"
				>
					<Button
						:label="addButtonText"
						class="btn-primary text-white"
						@click="addRegister()"
					>
						<img
							style="margin-right: 10px"
							v-if="addButtonText == 'Añadir usuario'"
							src="@/assets/images/icons/add_user.svg"
							width="20"
						/>
						<img
							style="margin-right: 10px"
							v-if="addButtonText == 'Exportar'"
							src="@/assets/images/icons/export_icon.svg"
							width="20"
						/>
						{{ addButtonText }}
					</Button>
					<slot name="custom-actions"></slot>
				</div>
			</div>
			<div class="col-12 mt-4 d-flex">
				<template v-for="(filter, index) in filters" :key="index">
					<span
						v-if="filter.name == 'email'"
						class="p-input-icon-left email col-2 mr-custom-35 bg-inputs"
					>
						<InputText
							v-model="filter.value"
							:name="filter.name"
							:placeholder="filter.placeholder"
							class="br-8 w-100"
						/>
					</span>
				</template>
				<template v-if="type_filter == 1">
					<div class="p-input-icon-left col-2 mr-custom-35">
						<template v-for="(filter, index) in filters" :key="index">
							<Dropdown
								v-if="filter.name == 'version'"
								v-model="filter.value"
								optionLabel="label"
								optionValue="value"
								:showClear="true"
								:name="filter.name"
								:placeholder="filter.placeholder"
								:options="filter.options"
								class="br-8 bg-inputs bg-inputs col-3 w-100 version"
							/>
							<Dropdown
								v-if="filter.name == 'type_sale'"
								v-model="filter.value"
								optionLabel="label"
								optionValue="value"
								:showClear="true"
								:name="filter.name"
								:placeholder="filter.placeholder"
								:options="filter.options"
								class="br-8 bg-inputs bg-inputs col-3 w-100 type_sale"
								@change="onChange($event)"
							/>
							<Dropdown
								v-if="filter.name == 'status'"
								v-model="filter.value"
								optionLabel="label"
								optionValue="value"
								:showClear="true"
								:name="filter.name"
								:placeholder="filter.placeholder"
								:options="filter.options"
								class="br-8 bg-inputs bg-inputs col-3 w-100 status"
							/>
							<input
								hidden
								type="text"
								:name="filter.name"
								:value="filter.value"
							/>
						</template>
					</div>
					<div
						class="p-input-icon-left col-2 calendar mr-custom-35 br-8 bg-inputs bg-inputs-div"
					>
						<template v-for="(filter, index) in filters" :key="index">
							<Calendar
								v-if="filter.name == 'from'"
								v-model="filter.value"
								dateFormat="dd/mm/yy"
								:manualInput="false"
								class="w-100"
								inputClass="w-100 bg-inputs bg-inputs-div text-dark-blue"
								inputStyle="border-radius: 25px; padding-left: 0px; color: #0C6094 !important;"
								:name="filter.name"
								:placeholder="filter.placeholder"
								showButtonBar
							/>
						</template>
					</div>
					<div
						class="p-input-icon-left col-2 calendar mr-custom-35 br-8 bg-inputs bg-inputs-div"
					>
						<template v-for="(filter, index) in filters" :key="index">
							<Calendar
								v-if="filter.name == 'to'"
								v-model="filter.value"
								dateFormat="dd/mm/yy"
								:manualInput="false"
								class="w-100"
								inputClass="w-100 bg-inputs bg-inputs-div text-dark-blue"
								inputStyle="border-radius: 25px; padding-left: 0px; color: #0C6094 !important;"
								:name="filter.name"
								:placeholder="filter.placeholder"
								showButtonBar
							/>
						</template>
					</div>
					<div
						class="action-buttons d-flex gap-3 align-items-center justify-content-md-end pb-1 f-right col-3 add"
					>
						<template v-for="(filter, index) in filters" :key="index">
							<Button
								v-if="filter.name == 'button_filter'"
								:label="'Filtrar'"
								class="btn-primary text-white mr-custom-12"
								@click="filter.value = 1"
							/>
						</template>
						<slot name="custom-actions"></slot>
					</div>
				</template>
				<template v-else>
					<div class="p-input-icon-left col-3 mr-custom-35">
						<template v-for="(filter, index) in filters" :key="index">
							<Dropdown
								v-if="filter.name == 'version'"
								v-model="filter.value"
								optionLabel="label"
								optionValue="value"
								:showClear="true"
								:name="filter.name"
								:placeholder="filter.placeholder"
								:options="filter.options"
								class="br-8 bg-inputs bg-inputs col-3 w-100 version"
							/>
							<Dropdown
								v-if="filter.name == 'type_sale'"
								v-model="filter.value"
								optionLabel="label"
								optionValue="value"
								:showClear="true"
								:name="filter.name"
								:placeholder="filter.placeholder"
								:options="filter.options"
								class="br-8 bg-inputs bg-inputs col-3 w-100 type_sale"
							/>
							<Dropdown
								v-if="filter.name == 'status'"
								v-model="filter.value"
								optionLabel="label"
								optionValue="value"
								:showClear="true"
								:name="filter.name"
								:placeholder="filter.placeholder"
								:options="filter.options"
								class="br-8 bg-inputs bg-inputs col-3 w-100 status"
							/>
							<input
								hidden
								type="text"
								:name="filter.name"
								:value="filter.value"
							/>
						</template>
					</div>
					<div
						class="p-input-icon-left col-3 calendar mr-custom-35 br-8 bg-inputs bg-inputs-div"
					>
						<template v-for="(filter, index) in filters" :key="index">
							<Calendar
								v-if="filter.name == 'from'"
								v-model="filter.value"
								dateFormat="dd/mm/yy"
								:manualInput="false"
								class="w-100"
								inputClass="w-100 bg-inputs bg-inputs-div text-dark-blue"
								inputStyle="border-radius: 25px; padding-left: 0px; color: #0C6094 !important;"
								:name="filter.name"
								:placeholder="filter.placeholder"
								showButtonBar
							/>
						</template>
					</div>
					<div
						class="p-input-icon-left col-3 calendar mr-custom-35 br-8 bg-inputs bg-inputs-div"
					>
						<template v-for="(filter, index) in filters" :key="index">
							<Calendar
								v-if="filter.name == 'to'"
								v-model="filter.value"
								dateFormat="dd/mm/yy"
								:manualInput="false"
								class="w-100"
								inputClass="w-100 bg-inputs bg-inputs-div text-dark-blue"
								inputStyle="border-radius: 25px; padding-left: 0px; color: #0C6094 !important;"
								:name="filter.name"
								:placeholder="filter.placeholder"
								showButtonBar
							/>
						</template>
					</div>
					<div
						class="action-buttons d-flex gap-3 align-items-center justify-content-md-end pb-1 f-right col-2 add"
					>
						<template v-for="(filter, index) in filters" :key="index">
							<Button
								v-if="filter.name == 'button_filter'"
								:label="'Filtrar'"
								class="btn-primary text-white mr-custom-12"
								@click="filter.value = 1"
							/>
						</template>
						<slot name="custom-actions"></slot>
					</div>
				</template>
			</div>
		</template>
		<template v-else-if="type_filter == 3">
			<div class="col-12 row">
				<span class="p-input-icon-left col-6 search bg-inputs pr-2">
					<template v-for="(filter, index) in filters" :key="index">
						<InputText
							v-if="filter.name == 'buscar'"
							v-model="filter.value"
							:name="filter.name"
							:placeholder="filter.placeholder"
							class="br-8 w-100 h-100"
						/>
					</template>
				</span>
				<div class="p-input-icon-left col-3 br-8 bg-inputs">
					<div class="bg-inputs-div calendar br-8 custom">
						<template v-for="(filter, index) in filters" :key="index">
							<Calendar
								v-if="filter.name == 'from'"
								v-model="filter.value"
								dateFormat="dd/mm/yy"
								:manualInput="false"
								class="w-100"
								inputClass="w-100 bg-inputs bg-inputs-div text-dark-blue"
								inputStyle="border-radius: 25px; padding-left: 0px; color: #0C6094 !important;"
								:name="filter.name"
								:placeholder="filter.placeholder"
								showButtonBar
							/>
							<input
								hidden
								type="text"
								:name="filter.name"
								:value="filter.value"
							/>
						</template>
					</div>
				</div>
				<div class="p-input-icon-left col-3 br-8 bg-inputs pr-0">
					<div class="bg-inputs-div calendar br-8 custom">
						<template v-for="(filter, index) in filters" :key="index">
							<Calendar
								v-if="filter.name == 'to'"
								v-model="filter.value"
								dateFormat="dd/mm/yy"
								:manualInput="false"
								class="w-100"
								inputClass="w-100 bg-inputs bg-inputs-div text-dark-blue"
								inputStyle="border-radius: 25px; padding-left: 0px; color: #0C6094 !important;"
								:name="filter.name"
								:placeholder="filter.placeholder"
								showButtonBar
							/>
							<input
								hidden
								type="text"
								:name="filter.name"
								:value="filter.value"
							/>
						</template>
					</div>
				</div>
			</div>
			<div class="col-12 mt-4 d-flex mb-5">
				<template v-for="(filter, index) in filters" :key="index">
					<span
						v-if="filter.name == 'email'"
						class="p-input-icon-left email col-3 bg-inputs pr-10"
					>
						<InputText
							v-model="filter.value"
							:name="filter.name"
							:placeholder="filter.placeholder"
							class="br-8 w-100 h-100"
						/>
					</span>
					<div class="col-3 pr-10" v-if="filter.name == 'type_suscription'">
						<Dropdown
							v-model="filter.value"
							optionLabel="label"
							optionValue="value"
							:showClear="true"
							:name="filter.name"
							:placeholder="filter.placeholder"
							:options="filter.options"
							class="br-8 bg-inputs suscription w-100 h-100"
						/>
						<input
							hidden
							type="text"
							:name="filter.name"
							:value="filter.value"
						/>
					</div>
					<div class="col-3 pr-10" v-if="filter.name == 'status_suscription'">
						<Dropdown
							v-model="filter.value"
							optionLabel="label"
							optionValue="value"
							:showClear="true"
							:name="filter.name"
							:placeholder="filter.placeholder"
							:options="filter.options"
							class="br-8 bg-inputs suscription w-100 h-100"
							@change="onChange($event)"
						/>
						<input
							hidden
							type="text"
							:name="filter.name"
							:value="filter.value"
						/>
					</div>
					<input hidden type="text" :name="filter.name" :value="filter.value" />
				</template>
				<div
					class="action-buttons d-flex gap-3 align-items-center justify-content-md-end pb-1 f-right col-3 add"
				>
					<template v-for="(filter, index) in filters" :key="index">
						<Button
							v-if="filter.name == 'button_filter'"
							:label="'Filtrar'"
							class="btn-primary text-white mr-custom-12"
							@click="filter.value = 1"
						/>
					</template>
					<slot name="custom-actions"></slot>
				</div>
			</div>
		</template>
		<div v-if="stateVariable == 'users'">
			<div class="px-24 py-16 border-info">
				<span class="pr-16 border-right">Usuarios totales</span>
				<span class="text-blue-light pl-16">{{ total }}</span>
			</div>
		</div>
		<div v-if="stateVariable == 'concerts' || stateVariable == 'sales'">
			<div
				class="px-24 py-16 border-info"
				v-if="
					stateVariable == 'concerts' ||
					(stateVariable == 'sales' && this.type_sale_custom != 2)
				"
			>
				<span class="pr-16 border-right">Beneficio (20%)</span>
				<span class="text-blue-light pl-16 f-700"
					>{{ total_amount_benefit }} EUR</span
				>
			</div>
			<div class="px-24 py-16 border-info">
				<span class="pr-16 border-right">TOTAL</span>
				<span class="text-blue-light pl-16 f-700">{{ total_amount }} EUR</span>
			</div>
		</div>
	</form>

	<form v-else id="filters_custom" class="align-items-center gap-3 pb-1">
		<div class="col-12 row">
			<span class="p-input-icon-left col-6 search bg-inputs pr-2">
				<template v-for="(filter, index) in filters" :key="index">
					<InputText
						v-if="filter.name == 'buscar'"
						v-model="filter.value"
						:name="filter.name"
						:placeholder="filter.placeholder"
						class="br-8 w-100 h-100"
					/>
				</template>
			</span>
		</div>
		<div class="col-12 mt-4 d-flex mb-5">
			<template v-for="(filter, index) in filters" :key="index">
				<span
					v-if="filter.name == 'email'"
					class="p-input-icon-left email col-3 bg-inputs pr-10"
				>
					<InputText
						v-model="filter.value"
						:name="filter.name"
						:placeholder="filter.placeholder"
						class="br-8 w-100 h-100"
					/>
				</span>
				<input hidden type="text" :name="filter.name" :value="filter.value" />
			</template>
			<div class="p-input-icon-left col-3 br-8 bg-inputs pr-10">
				<div class="bg-inputs-div calendar br-8 custom">
					<template v-for="(filter, index) in filters" :key="index">
						<Calendar
							v-if="filter.name == 'from'"
							v-model="filter.value"
							dateFormat="dd/mm/yy"
							:manualInput="false"
							class="w-100"
							inputClass="w-100 bg-inputs bg-inputs-div text-dark-blue"
							inputStyle="border-radius: 25px; padding-left: 0px; color: #0C6094 !important;"
							:name="filter.name"
							:placeholder="filter.placeholder"
							showButtonBar
						/>
						<input
							hidden
							type="text"
							:name="filter.name"
							:value="filter.value"
						/>
					</template>
				</div>
			</div>
			<div class="p-input-icon-left col-3 br-8 bg-inputs pr-0">
				<div class="bg-inputs-div calendar br-8 custom">
					<template v-for="(filter, index) in filters" :key="index">
						<Calendar
							v-if="filter.name == 'to'"
							v-model="filter.value"
							dateFormat="dd/mm/yy"
							:manualInput="false"
							class="w-100"
							inputClass="w-100 bg-inputs bg-inputs-div text-dark-blue"
							inputStyle="border-radius: 25px; padding-left: 0px; color: #0C6094 !important;"
							:name="filter.name"
							:placeholder="filter.placeholder"
							showButtonBar
						/>
						<input
							hidden
							type="text"
							:name="filter.name"
							:value="filter.value"
						/>
					</template>
				</div>
			</div>
			<div
				class="action-buttons d-flex gap-3 align-items-center justify-content-md-end pb-1 f-right col-3 add"
			>
				<template v-for="(filter, index) in filters" :key="index">
					<Button
						v-if="filter.name == 'button_filter'"
						:label="'Filtrar'"
						class="btn-primary text-white mr-custom-12"
						@click="filter.value = 1"
					/>
				</template>
				<slot name="custom-actions"></slot>
			</div>
		</div>
	</form>
</template>

<script>
	import InputText from "primevue/inputtext";
	import Calendar from "primevue/calendar";
	import Dropdown from "primevue/dropdown";
	import Consts from "../../../consts";

	import { mapMutations } from "vuex";

	export default {
		components: {
			InputText,
			Calendar,
			Dropdown,
		},
		data() {
			return {
				type_sale: 1,
			};
		},
		props: {
			filters: {
				type: Array,
				default: () => [],
			},
			type_filter: {
				type: Number,
				default: 0,
			},
			addButtonText: {
				type: String,
				default: "",
			},
			stateVariable: {
				type: String,
				required: true,
			},
			total: {
				type: Number,
				default: 0,
			},
			type_sale_custom: {
				type: Number,
				default: 1,
			},
			total_amount: {
				type: Number,
				default: 0,
			},
			total_amount_benefit: {
				type: Number,
				default: 0,
			},
		},
		methods: {
			...mapMutations(["toggleFormDialog"]),
			addRegister() {
				this.toggleFormDialog({
					stateVariable: this.stateVariable,
					show: true,
				});

				if (this.addButtonText == "Exportar") {
					window.open(
						Consts.defaultApiRoute + "/export-document/" + this.type_sale,
						"_blank"
					);
				} else {
					this.$emit("addRegister");
				}
			},
			test() {
				this.filters.forEach((v) => {
					if (v.name == "buscar") {
						v.value = 2;
					}
				});
			},
			onChange($event) {
				this.type_sale = $event.value;
				this.$emit("change-value-type-sales", $event.value);
			},
		},
	};
</script>

<style lang="scss" scoped>
	$width: 10rem;

	.p-input-icon-left > i:first-of-type {
		z-index: 50;
	}

	:deep(.p-calendar .p-inputtext) {
		width: $width;
	}

	/*:deep(.p-dropdown) {
		width: $width;
	}*/

	:deep(input) {
		width: $width;
	}

	span {
		position: relative;
	}

	span.email {
		input {
			color: #0c6094 !important;
		}
	}

	span.email:before {
		content: "";
		position: absolute;
		left: 12px;
		top: 0;
		bottom: 0;
		width: 22px;
		background: url("@/assets/images/icons/email.svg") center / contain
			no-repeat;
	}

	span.search {
		input {
			color: #0c6094 !important;
		}
	}

	span.search:before {
		content: "";
		position: absolute;
		right: 35px;
		top: 0;
		bottom: 0;
		width: 22px;
		background: url("@/assets/images/icons/search_icon.svg") center / contain
			no-repeat;
	}

	span.search {
		input {
			padding-left: 15px !important;
		}
	}

	div.type_sale:before {
		content: "";
		position: absolute;
		left: 12px;
		top: 0;
		bottom: 0;
		width: 22px;
		background: url("@/assets/images/icons/type_sale_icon.svg") center / contain
			no-repeat;
	}

	div.version:before {
		content: "";
		position: absolute;
		left: 12px;
		top: 0;
		bottom: 0;
		width: 22px;
		background: url("@/assets/images/icons/version.svg") center / contain
			no-repeat;
	}

	div.status:before {
		content: "";
		position: absolute;
		left: 12px;
		top: 0;
		bottom: 0;
		width: 22px;
		background: url("@/assets/images/icons/icon_status.svg") center / contain
			no-repeat;
	}

	div.suscription:before {
		content: "";
		position: absolute;
		left: 12px;
		top: 0;
		bottom: 0;
		width: 22px;
		background: url("@/assets/images/icons/icon_suscription.svg") center /
			contain no-repeat;
	}

	.div.rol > span,
	div.calendar > span,
	div.version > span {
		padding-left: 50px !important;
	}

	div.calendar:before,
	div.calendar.custom:before {
		content: "";
		position: absolute;
		left: 12px;
		top: 0;
		bottom: 0;
		width: 22px;
		z-index: 9999;
		background: url("@/assets/images/icons/calendar_icon.svg") center / contain
			no-repeat;
	}

	div.calendar.custom:before {
		margin-left: 12px;
	}

	div.calendar:after {
		content: "";
		position: absolute;
		right: 20px;
		top: 0;
		bottom: 0;
		width: 22px;
		background: url("@/assets/images/icons/arrow_down_blue.svg") center /
			contain no-repeat;
	}

	div.calendar > span {
		vertical-align: text-top;
	}

	.mr-custom-35 {
		margin-right: 35px;
	}

	.mr-custom-12 {
		margin-right: 12px;
	}

	.px-24 {
		padding-left: 24px;
		padding-right: 24px;
	}

	.py-16 {
		padding-top: 16px;
		padding-bottom: 16px;
	}

	.border-info {
		border-color: #d9dbe4 !important;
		border-width: 1px;
		border-style: solid;
		border-radius: 8px;
		width: fit-content;
		float: right;
		margin-right: 20px;
		margin-top: 24px;
		font-weight: 500;
	}

	.pr-16 {
		padding-right: 16px;
	}

	.pr-0 {
		padding-right: 0px;
	}

	.pl-16 {
		padding-left: 16px;
	}
	.border-right {
		border-right-color: #d9dbe4;
		border-right-style: solid;
		border-right-width: 1px;
	}

	.p-dropdown-label.p-inputtext {
		margin-left: 30px !important;
	}

	.f-700 {
		font-weight: 700;
	}

	.text-dark-blue {
		color: #0c6094 !important;
	}

	.px-2 {
		padding-left: 5px;
		padding-right: 5px;
	}

	.mx-2 {
		padding-left: 5px;
		padding-right: 5px;
	}

	.gap-20 {
		column-gap: 20px;
	}

	.pr-10 {
		padding-right: 10px;
	}
</style>
