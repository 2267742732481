import Form from "vform";
import http from "../axios";
import { func } from "../helpers";

const actions = {
    // Auth actions
    async login({ state }, { form, errors }) {
        try {
            const response = await form.post(state.baseURL + "/login");

            localStorage.setItem("accessToken", response.data.token);
            if(response.data.user.role_id == 1){
                window.location.href = "/admin/users";
            }else{
                window.location.href = "/users/songs";
            }
            
        } catch (error) {
            if (error.response?.data?.message) {
                errors.set("email", error.response.data.message);
            }
        }
    },
    setFormAccessToken() {
        const instance = http.create({
            headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
        });

        Form.axios = instance;
    },
    logout() {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userLogged");

        window.location.href = "/login";
    },

    // Helper actions
    copyToClipboard({ state }, data) {
        try {
            navigator.clipboard.writeText(data);
            state.successToast = "Texto copiado al portapapeles";
        } catch (error) {
            state.errorToast = "Error al copiar al portapapeles";
        }
    },
    async downloadFile({ state }, params) {
        try {
            const response = await http({
                method: "post",
                url: params.downloadRoute,
                params: {
                    folder: params.folder,
                    fileName: params.fileName,
                    databaseFileColumn: params.databaseFileColumn,
                    databaseFileNameColumn: params.databaseFileNameColumn,
                },
                responseType: !params.isFromDatabase ? "blob" : "",
            });

            let link = null;

            if (params.isFromDatabase) {
                link = document.createElement("a");
                link.href = response.data.fileData;

                link.setAttribute("download", response.data.fileName);

                document.body.appendChild(link);
                link.click();
            } else {
                const fileName =
                    response.headers["content-disposition"].split("filename=")[1];

                const url = window.URL.createObjectURL(new Blob([response.data]));

                link = document.createElement("a");
                link.href = url;

                link.setAttribute("download", fileName);

                document.body.appendChild(link);
                link.click();
            }

            state.successToast = "Archivo descargado correctamente";

            if (link != null) {
                link.parentNode.removeChild(link);
            }
        } catch (error) {
            console.error(error);
            state.errorToast = true;

            return error;
        }
    },

    // Registers functions
    async sendForm({ state }, { method = "post", form, url, errors }) {
        try {
            let response;

            state.loading = true;

            if (method == "post") {
                response = await form.post(state.baseURL + url);
            } else if (method == "put") {
                response = await form.put(state.baseURL + url);
            }else if (method == "delete") {
                response = await form.delete(state.baseURL + url);
            }

            state.successToast = "Registro guardado correctamente";
            
            state.loading = false;
            return response;
        } catch (error) {
            console.error(error);

            state.loading = false;

            if (error.response?.data?.message) {
                state.errorToast = error.response.data.message;
            }

            if (error.response?.data) {
                for (const key in error.response.data) {
                    errors.set(key, error.response.data[key]);
                }
            }

            return error.response;
        }
    },
    async deleteRegisters({ state }, { url, ids = null }) {
        try {
            state.loading = true;

            const response = await http({
                method: "delete",
                url: url,
                data: {
                    ids,
                },
            });

            state.loading = false;
            state.successToast = "Registro/s borrado/s correctamente";
            state.datatableDefaults.loading = false;

            return response;
        } catch (error) {
            console.error(error);

            state.loading = false;

            if (error.response?.data?.message) {
                state.errorToast = error.response.data.message;
            }

            return error.response;
        }
    },
    async blockUser({ state }, { url, ids = null }) {
        try {
            state.loading = true;

            const response = await http({
                method: "delete",
                url: url,
                data: {
                    ids,
                },
            });

            state.loading = false;
            state.successToast = "Usuario bloqueado correctamente";

            return response;
        } catch (error) {
            console.error(error);

            state.loading = false;

            if (error.response?.data?.message) {
                state.errorToast = error.response.data.message;
            }

            return error.response;
        }
    },
    async deleteSong({ state }, { url, ids = null }) {
        try {
            state.loading = true;

            const response = await http({
                method: "delete",
                url: url,
                data: {
                    ids,
                },
            });

            state.loading = false;
            state.successToast = "Registro/s borrado/s correctamente";

            const response2 = await http({
                url: "songs",
                params: "",
            });

            state.datatableDefaults.loading = false;
            state["songs"].list = response2.data["songs"];
            state["songs"].listTotal = response2.data.total;
            state["songs"].total_amount = response2.data.total_amount;
            state["songs"].total_amount_benefit = response2.data.total_amount_benefit;

            return response;
        } catch (error) {
            console.error(error);

            state.loading = false;

            if (error.response?.data?.message) {
                state.errorToast = error.response.data.message;
            }

            return error.response;
        }
    },
    async addSong({ state }, { url, params }) {
        try {
            state.loading = true;
            let response = await params.post(state.baseURL + url);

            state.loading = false;
            state.successToast = "Canción añadida correctamente";

            const response2 = await http({
                url: "songs",
                params: "",
            });

            state.datatableDefaults.loading = false;
            state["songs"].list = response2.data["songs"];
            state["songs"].listTotal = response2.data.total;
            state["songs"].total_amount = response2.data.total_amount;
            state["songs"].total_amount_benefit = response2.data.total_amount_benefit;

            return response;
        } catch (error) {
            console.error(error);

            state.loading = false;

            if (error.response?.data?.message) {
                state.errorToast = error.response.data.message;
            }

            return error.response;
        }
    },
    
    async getRegisters({ state }, params) {
        try {
            if (
                params.showLoading == null ||
                params.showLoading == undefined ||
                params.showLoading
            ) {
                state.datatableDefaults.loading = true;
            }

            var formProps;
            var type = params.type;
            if (type != 2) {
                formProps = func.formatFilters("filters");
            } else {
                formProps = func.formatFilters("filters_custom");
            }


            let httpParams = {
                ...formProps,
            };

            if (
                params.getAll == undefined ||
                (params.getAll != undefined && !params.getAll)
            ) {
                httpParams.pagination = {
                    currentPage: params?.page != null ? params.page : 0,
                    rows:
                        params?.rows != null ? params.rows : state.datatableDefaults.rows,
                };
            }

            if (params.additionalParams != undefined) {
                httpParams = {
                    ...httpParams,
                    ...params.additionalParams,
                };
            }

            const response = await http({
                url: params.route,
                params: httpParams,
            });

            state.datatableDefaults.loading = false;
            state[params.stateVariable].list = response.data[params.stateVariable];
            state[params.stateVariable].listTotal = response.data.total;
            state[params.stateVariable].total_amount = response.data.total_amount;
            state[params.stateVariable].total_amount_benefit = response.data.total_amount_benefit;

        } catch (error) {
            console.error(error);

            if (error.response.data.message === "CSRF token mismatch.") {
                location.reload();
            }

            if (
                error.response.data?.message == "User not found" ||
                error.response.data?.message == "Unauthorized" ||
                error.response.data?.message == "Unauthenticated."
            ) {
                window.localStorage.clear();
                window.location.href = "/login";
            }

            state.showFormGeneralErrorToast = true;

            return error;
        }
    },

    //Modificar el estado de un concierto
    async statusConcert({ state }, params) {
        var custom_params = { "id_concert": params }
        try {
            state.loading = true;

            const response = await http({
                method: "post",
                url: "validate_concert",
                params: custom_params
            });

            state.loading = false;
            state.successToast = "Concierto modificado correctamente";

            return response;
        } catch (error) {
            console.error(error);

            state.loading = false;

            if (error.response?.data?.message) {
                state.errorToast = error.response.data.message;
            }

            return error.response;
        }
    },

    //Mostrar pistas de una canción
    async getInfoSong({ state }, params) {
        try {
            state.loading = true;

            const response = await http({
                method: "post",
                url: "get_info_tracks_song",
                params: params
            });

            state.song.tracks = response.data.array_tracks;
            state.loading = false;

            return response;
        } catch (error) {
            console.error(error);

            state.loading = false;

            if (error.response?.data?.message) {
                state.errorToast = error.response.data.message;
            }

            return error.response;
        }
    },

};

export default actions;