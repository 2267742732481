<template>
	<div>
		<SalesTable :route="route" :stateVariable="stateVariable" />
	</div>
</template>

<script>
	import SalesTable from "./SalesTable.vue";

	export default {
		components: {
			SalesTable,
		},
		data() {
			return {
				route: "sales",
				stateVariable: "sales",
			};
		},
	};
</script>
