<template>
	<Card class="custom-card">
		<template #content>
			<div class="d-flex justify-content-between align-items-center">
				<div
					class="d-none d-md-flex align-items-center text-blue-light h4 mb-0 px-2"
				>
					<b>{{ $route.name }}</b>
				</div>
				<Breadcrumb />
				<CurrentUser />
			</div>
		</template>
	</Card>
</template>

<script>
	import Card from "primevue/card";
	import Breadcrumb from "../partials/Breadcrum.vue";
	import CurrentUser from "../partials/CurrentUser.vue";

	export default {
		components: {
			Breadcrumb,
			Card,
			CurrentUser,
		},
	};
</script>

<style lang="scss" scoped>
	.custom-card {
		padding: 0;
		border-radius: 0;
		box-shadow: none !important;

		/*background: radial-gradient(65.95% 118.97% at 50.85% -7.54%, #00A6FF 0%, #018FEF 29%, #0654C8 91%, #074BC2 100%);*/

		:deep(.p-card-content) {
			padding: 0;
		}

		@media (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
			:deep(.p-card-body) {
				padding: 0;
			}
		}
	}
</style>
