<template>
	<TableDefault
		ref="table"
		:route="route"
		:stateVariable="stateVariable"
		:list="concerts.list"
		:total="concerts.listTotal"
		:filters="filters"
		:type_filter="2"
		:delete="'concerts'"
		:showActionsColumn="false"
		:showDeleteButton="false"
		:showTitle="false"
		:addButtonText="''"
		:showSelectMultipleColumn="false"
		:total_amount="concerts.total_amount.toFixed(2)"
		:total_amount_benefit="concerts.total_amount_benefit.toFixed(2)"
	>
		<template #columns>
			<Column>
				<template #header>
					<span style="width: 200px !important">CONCIERTOS</span>
				</template>
				<template #body="slotProps">
					<div
						class="d-flex align-items-center gap-2"
						style="width: 200px !important"
					>
						<img src="@/assets/images/icons/icon_group.svg" width="32" />
						<span class="fw-700">{{ slotProps.data.group }}</span>
					</div>
				</template>
			</Column>
			<Column>
				<template #header>
					<span style="width: 400px !important">SOLICITANTE</span>
				</template>
				<template #body="slotProps">
					<span class="fw-700" style="width: 400px !important">
						{{ slotProps.data.user?.email }}
					</span>
				</template>
			</Column>
			<Column>
				<template #header>
					<span style="width: 200px !important">PRECIO</span>
				</template>
				<template #body="slotProps">
					<span class="fw-700 text-blue-light" style="width: 200px !important">
						{{ slotProps.data.price }} EUR
					</span>
				</template>
			</Column>
			<Column>
				<template #header>
					<span style="width: 200px !important">Nº ENTRADAS</span>
				</template>
				<template #body="slotProps">
					<div
						class="d-flex align-items-center gap-2"
						style="width: 200px !important"
					>
						<img src="@/assets/images/icons/icon_tickets.svg" width="20" />
						<span class="fw-700">
							{{ slotProps.data.num_tickets }}
						</span>
					</div>
				</template>
			</Column>
			<Column>
				<template #header>
					<span style="width: 200px !important">ENTRADAS VENDIDAS</span>
				</template>
				<template #body="slotProps">
					<div
						class="d-flex align-items-center gap-2"
						style="width: 200px !important"
					>
						<img src="@/assets/images/icons/icon_sale_tickets.svg" width="20" />
						<span class="fw-700">
							{{ slotProps.data.tickets_sold }}
						</span>
					</div>
				</template>
			</Column>
			<Column>
				<template #header>
					<span style="width: 200px !important">BENEFICIO</span>
				</template>
				<template #body="slotProps">
					<span class="fw-700 text-blue-light" style="width: 200px !important">
						{{
							(
								slotProps.data.price *
								slotProps.data.tickets_sold *
								0.2
							).toFixed(2)
						}}
						EUR
					</span>
				</template>
			</Column>
			<Column>
				<template #header>
					<span style="width: 200px !important">FECHA ACTUACIÓN</span>
				</template>
				<template #body="slotProps">
					<span class="gray-color" style="width: 200px !important">
						{{
							$helper.formatDate(slotProps.data.performance_date, "DD/MM/YYYY")
						}}
					</span>
				</template>
			</Column>
			<Column>
				<template #header>
					<span style="width: 100px !important">ESTADO</span>
				</template>
				<template #body="slotProps">
					<div class="d-flex" style="width: 100px !important">
						<div
							v-if="slotProps.data.status == 1"
							class="bg-light-success px-3 py-1 text-success br-8 f-12 rol"
						>
							Validado
						</div>
						<div
							v-else-if="slotProps.data.status == 2"
							class="bg-light-yellow px-3 py-1 text-yellow br-8 f-12 rol"
						>
							Pendiente
						</div>
					</div>
				</template>
			</Column>
			<Column header="">
				<template #body="slotProps">
					<Button
						class="table-custom-button-action d-flex gap-3"
						style="width: 100px !important"
					>
						<img
							v-if="
								$helper.checkUserHasPermission(route, 'update') &&
								slotProps.data.status == 1
							"
							src="@/assets/images/icons/icon_validate.svg"
							@click="updateStatus(slotProps.data.id)"
						/>
						<img
							v-if="
								$helper.checkUserHasPermission(route, 'update') &&
								slotProps.data.status == 2
							"
							src="@/assets/images/icons/icon_validate_green.svg"
							@click="updateStatus(slotProps.data.id)"
						/>
						<img
							src="@/assets/images/icons/icon_application.svg"
							@click="showRegister(slotProps.data, 'delete')"
						/>
					</Button>
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Column from "primevue/column";
	import { mapState, mapActions, mapMutations } from "vuex";

	export default {
		components: {
			Column,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "buscar",
						value: null,
						type: "string",
						placeholder: "Buscar...",
					},
					{
						name: "status",
						value: null,
						type: "dropdown",
						placeholder: "Estado",
						options: [
							{
								label: "Validado",
								value: "1",
							},
							{
								label: "Pendiente",
								value: "2",
							},
						],
					},
					{
						name: "from",
						value: null,
						type: "date",
						placeholder: "Desde",
					},
					{
						name: "to",
						value: null,
						type: "date",
						placeholder: "Hasta",
					},
					{
						name: "button_filter",
						value: 0,
					},
				],
			};
		},
		methods: {
			...mapActions(["statusConcert"]),
			...mapMutations([
				"toggleFormDialog",
				"changeFormDialogMode",
				"changeCurrentRegister",
				"toggleInfoDialog",
			]),
			firstTwoLetters(name) {
				return name.substring(0, 2);
			},
			showRegister(e, type, info = false) {
				if (!info) {
					this.$refs.table.showRegister(e, type);
				} else {
					const register = e.data != undefined ? e.data : e;

					this.changeCurrentRegister({
						stateVariable: this.stateVariable,
						register,
					});

					this.toggleInfoDialog(true);
				}
			},
			updateStatus(id) {
				this.statusConcert(id).then(() => {
					this.$refs.table.getList();
				});
			},
		},
		computed: {
			...mapState(["concerts"]),
		},
	};
</script>

<style lang="scss" scoped>
	.p-avatar.not-active,
	.rol.not-active {
		background-color: #abafb3 !important;
		color: white !important;
		border-width: 0px;
	}

	.gray-color {
		color: #b5b5c3;
	}

	.fw-500 {
		font-weight: 500;
	}

	.fw-700 {
		font-weight: 700;
	}

	.border-1 {
		border-width: 1px;
		border-style: solid;
	}

	.not-active {
		filter: grayscale(100%);
	}
</style>
