<template>
	<div
		class="current-user d-flex align-items-center justify-content-between gap-3 p-2"
	>
		<Button
			@click="toggleSidebar(true)"
			icon="pi pi-bars"
			class="p-button-rounded bg-primary d-md-none"
		/>
		<div
			v-if="userLogged != null"
			class="name d-flex align-items-center cursor-pointer"
			@click="toggleUpdatePassword(!this.showUpdatePassword)"
		>
			<span class="text-blue"
				>¡Hola!, <b> {{ userLogged.name }}</b></span
			>
			<!--<Avatar :label="firstLetter" class="ms-2 d-none d-md-flex" />-->
			<img
				class="mx-3 d-none d-md-flex"
				src="@/assets/images/icons/user_icon.svg"
			/>
			<i
				class="fa-solid fa-sort-down ms-2 text-white cursor-pointer"
				style="margin-top: -0.5rem"
			></i>
		</div>
	</div>
</template>

<script>
	//import Avatar from "primevue/avatar";
	import { mapMutations, mapState } from "vuex";

	export default {
		components: {
			//Avatar,
		},
		computed: {
			currentDate() {
				const date = new Date();
				const day = date.getDate();
				const month = date.getMonth() + 1;

				const monthNames = [
					"Enero",
					"Febrero",
					"Marzo",
					"Abril",
					"Mayo",
					"Junio",
					"Julio",
					"Agosto",
					"Septiembre",
					"Octubre",
					"Noviembre",
					"Diciembre",
				];

				return `${day} de ${monthNames[month - 1]}`;
			},
			firstLetter() {
				return this.userLogged.name.charAt(0);
			},
			...mapState(["userLogged", "showUpdatePassword"]),
		},
		methods: {
			...mapMutations(["toggleSidebar", "toggleUpdatePassword"]),
		},
	};
</script>

<style lang="scss" scoped>
	.today {
		position: relative;

		&:hover {
			&::after {
				opacity: 0;
			}
		}
	}

	.vertical-line {
		width: 2px;
		height: 60%;
		background-color: lightgray;
		transition: $transition;
	}

	:deep(.p-avatar) {
		background-color: rgba($secondary, 20%);
		color: $secondary;

		.p-avatar-text {
			font-weight: bold;
		}
	}

	@media only screen and (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
		.current-user {
			width: 100%;
		}
	}

	.text-blue {
		color: #0c6094;
	}
</style>
