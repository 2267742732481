<template>
	<div class="w-100 d-flex flex-column">
		<div v-for="(item, index) in items" :key="index" class="mb-2">
			<div
				v-if="
					!item.items &&
					(item.permission != null
						? $helper.checkUserHasPermission(item.permission)
						: true)
				"
			>
				<div class="pointer">
					<Link :item="item" @click="toggleSidebar(false)" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Link from "./Link.vue";
	import { mapMutations, mapState } from "vuex";

	export default {
		components: {
			Link,
		},
		data() {
			return {
				items: [
					
				],
			};
		},
		methods: {
			...mapMutations(["toggleSidebar"]),
			setActiveLink() {
				this.items.forEach((item) => {
					item.active = item.href == this.$route.path;
				});
			},
		},
		computed: {
			...mapState(["userLogged"]),
		},
		mounted() {
			if(this.userLogged.role_id == 2){
				this.items = [
					{
						label: "Canciones",
						href: "/users/songs",
						permission: "songs",
						width: "14",
					},
				]

			}else{
				this.items = [
					{
						label: "Usuarios",
						href: "/admin/users",
						permission: "users",
						width: "14",
					},
					{
						label: "Ventas",
						href: "/admin/sales",
						permission: "sales",
						width: "24",
					},
					{
						label: "Suscripciones",
						href: "/admin/suscriptions",
						permission: "suscriptions",
					},
					{
						label: "Conciertos",
						href: "/admin/concerts",
						permission: "concerts",
					},
					{
						label: "Perfil",
						href: "/admin/profile",
						permission: "profile",
					},
				];
			}
			this.setActiveLink();
		},
		watch: {
			$route() {
				this.setActiveLink();
			},
		},
	};
</script>
<style lang="scss" scoped></style>
