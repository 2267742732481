<template>
	<TableDefault
		ref="table"
		:route="'list-suscriptions'"
		:stateVariable="stateVariable"
		:list="suscriptions_actives.list"
		:total="suscriptions_actives.listTotal"
		:filters="filters"
		:type_filter="3"
		:showActionsColumn="false"
		:showDeleteButton="false"
		:showTitle="false"
		:addButtonText="''"
		:showSelectMultipleColumn="false"
	>
		<template #columns>
			<Column field="name">
                <template #header>
                    <span style="width: 300px !important;">NOMBRE</span>
                </template>
                <template #body="slotProps">
                    <span style="width: 300px !important;">{{ slotProps.data.name }}</span>
                </template>
            </Column>
			<Column field="email">
                <template #header>
                    <span style="width: 400px !important;">EMAIL</span>
                </template>
                <template #body="slotProps">
                    <span  style="width: 400px !important;" v-html="slotProps.data.email" />
                </template>
            </Column>
			<Column header="TIPO">
				<template #body="slotProps">
					<div class="d-flex" style="width: 200px;">
						<div
							v-if="slotProps.data.type == 1"
							class="py-1 text-secondary fw-700 f-14"
						>
							Mensual
						</div>
						<div
							v-else-if="slotProps.data.type == 2"
							class="text-secondary py-1 fw-700 f-14" 
						>
							Anual
						</div>
						
					</div>
				</template>
			</Column>
			<Column >
				<template #header>
                    <span style="width: 200px !important;">ESTADO</span>
                </template>
				<template #body="slotProps">
					<div class="d-flex" style="width: 200px;">
						<div
							v-if="slotProps.data.status == 1"
							class="bg-light-success px-3 py-1 text-success br-8 f-12 rol"
						>
							Activa
						</div>
						<div
							v-else-if="slotProps.data.status == 2"
							class="bg-light-danger text-danger px-3 py-1 br-8 f-12 rol" 
						>
							Cancelada
						</div>
						
					</div>
				</template>
			</Column>
			<Column field="date">
                <template #header>
                    <span style="width: 300px !important;">FECHA</span>
                </template>
                <template #body="slotProps">
                    <span style="width: 300px !important;">{{ $helper.formatDate(slotProps.data.created_at, "DD/MM/YYYY") }}</span>
                </template>
            </Column>
		</template>
	</TableDefault>
</template>

<script>
	import Column from "primevue/column";
	import { mapState } from "vuex";

	export default {
		components: {
			Column,
		},
		data() {
			return {
				filters: [
					{
						name: "buscar",
						value: null,
						type: "string",
						placeholder: "Buscar...",
					},
					{
						name: "from",
						value: null,
						type: "date",
						placeholder: "Desde",
					},
					{
						name: "to",
						value: null,
						type: "date",
						placeholder: "Hasta",
					},
					{
						name: "email",
						value: null,
						type: "string",
						placeholder: "Filtrar por email",
					},
					{
						name: "type_suscription",
						value: null,
						type: "dropdown",
						placeholder: "Tipo de suscripción",
						options: [
							{
								label: "Mensual",
								value: "1",
							},
							{
								label: "Anual",
								value: "2",
							},
						],
					},
					{
						name: "status_suscription",
						value: null,
						type: "dropdown",
						placeholder: "Estado de suscripción",
						options: [
							{
								label: "Activa",
								value: "1",
							},
							{
								label: "Cancelada",
								value: "2",
							},
						],
					},
                    {
						name: "button_filter",
						value: 0
					}
				],
				route: "suscriptions_actives",
				stateVariable: "suscriptions_actives"
			};
		},
		computed: {
			...mapState(["suscriptions_actives"]),
		},
	};
</script>

<style lang="scss" scoped>
	.p-avatar.not-active, .rol.not-active {
		background-color: #ABAFB3 !important;
		color: white !important;
		border-width: 0px;
	}

	.f-12{
		font-size: 12px;
	}

	.f-14{
		font-size: 14px;
	}

	.br-8{
		border-radius: 8px !important;
	}

	.gray-color{
		color: #B5B5C3; 
	}

	.fw-500{
		font-weight: 500;
	}

	.border-1{
		border-width: 1px;
		border-style: solid;
	}

	.not-active {
		filter: grayscale(100%);
	}

	.fw-700{
		font-weight: 700;
	}
</style>
